import React from "react";
import {DrmPfApiUsagesOptions} from "@/containers/customer/usageHistory/useDrmPfApiUsages";
import {AllBills} from "@/containers/customer/drmPf/types";
import {ToolTipTd} from "@/components/Customer/UsageHistory/ToolTipTd";

interface BillDataProps {
  bills: AllBills[];
}

export const BillData = ({bills}: BillDataProps) => {
  const UsagesOptions = DrmPfApiUsagesOptions();
  return (

    <div className="uk-width-1-1 uk-overflow-auto">
      <table className=" uk-margin-top">
        <thead>
        <tr>
          {UsagesOptions.drm_pf_api_summary_th.map((item, index) => {
            const replaced_label = item.label.split(/(\n)/).map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {item.match(/\n/) ? <br/> : item}
                </React.Fragment>
              );
            });
            const style: React.CSSProperties = {
              width: item.width
            };
            return (
              <th className={`label uk-text-center ${item.bold && "uk-text-bold"}`}
                  style={style}
                  rowSpan={item.rowspan}
                  colSpan={item.colspan}
                  key={index}
              >
                {replaced_label}
              </th>
            );
          })}
        </tr>
        <tr>
          {UsagesOptions.drm_pf_api_summary_th_2nd.map((item, index) => {
            const style: React.CSSProperties = {
              width: item.width
            };
            return (
              <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
            );
          })}
        </tr>
        </thead>
        <tbody>
        {Object.values(bills).length !== 0 && Object.values(bills).map((item, index) => (
          <tr key={index}>
            <ToolTipTd className="uk-text-left">{item.customer_number}</ToolTipTd>
            <ToolTipTd className="uk-text-left">{item.customer_type}</ToolTipTd>
            <ToolTipTd className="uk-text-left">{item.customer_group_type}</ToolTipTd>
            <ToolTipTd className="uk-text-left">{item.group_name}</ToolTipTd>
            <ToolTipTd className="uk-text-left">{item.department_name}</ToolTipTd>
            <ToolTipTd className="uk-text-center">{item.paymentType}</ToolTipTd>
            <ToolTipTd className="uk-text-right">¥ {item.basicFee}</ToolTipTd>
            <ToolTipTd className="uk-text-right">{item.validTotalAll} 件</ToolTipTd>
            <ToolTipTd className="uk-text-right">¥ {item.undiscountPriceTotal}</ToolTipTd>
            <ToolTipTd className="uk-text-right">¥ {item.discountAmount}</ToolTipTd>
            <ToolTipTd className="uk-text-right">{item.realDiscountRate} %</ToolTipTd>
            <ToolTipTd className="uk-text-right">¥ {item.discountPriceTotal}</ToolTipTd>
            <ToolTipTd className="uk-text-right">{item.rate} %</ToolTipTd>
            <ToolTipTd className="uk-text-right">{item.termDiscounts} %</ToolTipTd>
            <ToolTipTd className="uk-text-right">¥ {item.allDiscountPriceTotal}</ToolTipTd>
            <ToolTipTd className="uk-text-right uk-text-bold">¥ {item.billingAmount}</ToolTipTd>
            <ToolTipTd className="uk-text-right uk-text-bold">{item.tax} %</ToolTipTd>
            <ToolTipTd className="uk-text-right uk-text-bold">¥ {item.includeTax}</ToolTipTd>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}