import {
  PostInformationParams,
  getInformation,
  postInformation, Information,
} from "@/api/information";
import ConfirmModal from "@/components/ConfirmModal";
import { CheckBox } from "@/components/Form/CheckBox";
import { TextArea } from "@/components/Form/TextArea";
import { TextBox } from "@/components/Form/TextBox";
import {MESSAGE_NO_E08, MESSAGE_NO_E35, MESSAGE_NO_E37, MESSAGE_NO_E39} from "@/config";
import { useLoadingStore } from "@/stores/loading";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {To, useLocation, useNavigate, useParams} from "react-router-dom";
import SubHeader from "@/components/SubHeader";
import {SelectBox} from '@/components/Form/SelectBox'
import {axios} from '@/lib/axios'
import * as Config from '@/config'
import {RadioButton} from '@/components/Form/RadioButton'
import * as Common from '@/utils/common'
import {useBlockerStore} from '@/stores/blocker'
import BlockModal from '@/components/BlockModal'

const NOTIFY_EMAIL_CHECKED_VALUE = "email";
const ENABLE_NOTIFY_STATUS_ID = "3";

interface Form {
  title: string;
  body: string;
  drm_pf_information_type_id: string;
  drm_pf_information_status_id: string;
  release_date: string;
  is_notify: {
    [NOTIFY_EMAIL_CHECKED_VALUE]: typeof NOTIFY_EMAIL_CHECKED_VALUE | false;
  };
}

const DrmPfInformationEdit: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const informationId = useParams().id;
  const setLoading = useLoadingStore((state) => state.setLoading);
  const isDuplicate = location.pathname.includes("/duplicate");

  const [isBlocker, setBlocker] = useBlockerStore((state) => [
    state.isBlocker,
    state.setBlocker,
  ]);
  const [navigatePath, setNavigatePath] = useState<To | number>("");
  const [isConfirmNotify, setIsConfirmNotify] = useState(false);
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [drmPfInformationStatuses, setDrmPfInformationStatuses] = useState<any[]>([]);
  const [drmPfInformationTypes, setDrmPfInformationTypes] = useState<any[]>([]);
  const [lastInformation, setLastInformation] = useState<any | undefined>(undefined);

  const [formData, setFormData] = useState<Form>();

  function getFormInputs() {
    setLoading(true);
    axios.get('/api/v1/drmPfInformation/formInputs').then(result => {
      setDrmPfInformationStatuses(result.data.drmPfInformationStatuses);
      setDrmPfInformationTypes(result.data.drmPfInformationTypes);
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (!isBlocker && navigatePath !== "") {
      typeof navigatePath === "number" ? navigate(navigatePath) : navigate(navigatePath);
    }
    return () => {
    };
  }, [isBlocker, navigatePath]);

  useEffect(() => {
    setBlocker(true);
    getFormInputs();
  }, []);

  useEffect(() => {
    if (!informationId || drmPfInformationStatuses.length === 0 || drmPfInformationTypes.length === 0) return;

    setLoading(true);

    axios.get("/api/v1/drmPfInformation/view", {
      params: { id: Number(informationId) },
    }).then((res) => {
        const drmPfInformation = res.data.drmPfInformation;
        const statusId = String(drmPfInformation.drm_pf_information_status_id);
        setFormData({
          title: drmPfInformation.title,
          body: drmPfInformation.body,
          drm_pf_information_type_id: String(drmPfInformation.drm_pf_information_type_id),
          drm_pf_information_status_id: statusId,
          release_date: drmPfInformation.release_date,
          is_notify: {
            [NOTIFY_EMAIL_CHECKED_VALUE]: false,
          }
        });
        setLastInformation({
          modified_by: `${drmPfInformation.modified_user.last_name} ${drmPfInformation.modified_user.first_name}`,
          modified: drmPfInformation.modified,
        })
      })
      .catch(() => {
        setErrorMessage(MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [informationId, isDuplicate, setLoading, drmPfInformationStatuses, drmPfInformationTypes]);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    criteriaMode: "all",
    defaultValues: {},
    values: formData,
  });

  const selectedStatusId = useWatch({
    control,
    name: "drm_pf_information_status_id",
  });

  const title = informationId && isDuplicate ? "複製" : informationId && !isDuplicate ? "編集" : "新規作成";

  const breadCrumbs = [
    { title: "管理画面", path: "/admin" },
    { title: "お知らせ一覧", path: "/admin/portal" },
    { title },
  ];

  const onClickCancel = () => {
    setIsShowBackModal(true);
  };

  const onClickSave = () => {
    const data = getValues();
    if (data.is_notify[NOTIFY_EMAIL_CHECKED_VALUE] === NOTIFY_EMAIL_CHECKED_VALUE) {
      setIsConfirmNotify(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit: SubmitHandler<Form> = (data) => {
    setLoading(true);

    const params = {
      id: informationId && !isDuplicate ? Number(informationId) : undefined,
      title: data.title,
      body: data.body,
      drm_pf_information_type_id: data.drm_pf_information_type_id,
      drm_pf_information_status_id: data.drm_pf_information_status_id,
      release_date: data.release_date,
      is_notify_email: data.is_notify[NOTIFY_EMAIL_CHECKED_VALUE] === NOTIFY_EMAIL_CHECKED_VALUE,
    };

    axios.post("/api/v1/drmPfInformation/post", params)
      .then((response) => {
        console.log(response)
        const result = response.data.result;
        setBlocker(false);
        setNavigatePath(`/admin/portal/${result.id}`);
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          const errors = error?.response?.data?.errors;
          for (const key in errors) {
            const assertedKey = key as keyof Form;
            if (assertedKey) {
              setError(assertedKey, {
                type: "sever",
                message: Common.getNestedValue(errors[key]),
              });
            }
          }
        } else if (error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setErrorMessage(MESSAGE_NO_E37);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs}
                   title={informationId && !isDuplicate ? `お知らせ管理　${informationId.padStart(5, '0')}` : "お知らせ管理"} />
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div className="precaution">※は必須です</div>
          <div>
            <button
              className="uk-button--m uk-button-cancel"
              onClick={onClickCancel}
            >
              キャンセル
            </button>
            <button
              className="uk-button--m uk-button-refer uk-margin-left"
              onClick={onClickSave}
            >
              保存
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="drm-pf-information-admin-tbl uk-margin-small-top">
            <table className="uk-width-1-2">
              <tbody>
              <tr className="drm-pf-information-admin-tbl-header">
                <th className="label">
                  ステータス<span className="error">※</span>
                  {errors.drm_pf_information_status_id && (
                    <span className="error">必須項目です</span>
                  )}
                </th>
                <td className="content">
                  <SelectBox
                    name="drm_pf_information_status_id"
                    options={drmPfInformationStatuses.map((value: any) => {
                      return {
                        value: value.id,
                        label: `${value.code}: ${value.name}`,
                      }
                    })}
                    register={register}
                    rules={{ required: MESSAGE_NO_E08 }}
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="drm-pf-information-admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tbody>
              <tr className="drm-pf-information-admin-tbl-header">
                <th>
                  情報区分<span className="error">※</span>
                  {errors.drm_pf_information_type_id && (
                    <span className="error">必須項目です</span>
                  )}
                </th>
                <td>
                  <div className="uk-flex uk-flex-wrap">
                    <RadioButton
                      name="drm_pf_information_type_id"
                      options={drmPfInformationTypes.map((value: any) => {
                        return {
                          value: value.id,
                          label: `${value.code}: ${value.name}`,
                        }
                      })}
                      register={register}
                      rules={{ required: MESSAGE_NO_E08 }}
                      errors={errors}
                    />
                  </div>
                </td>

                <th>
                  公開日<span className="error">※</span>
                  {(errors.release_date) && (
                    <span className="error">必須項目です</span>
                  )}
                </th>
                <td>
                  <div className="uk-flex">
                    <TextBox
                      inputType="date"
                      name="release_date"
                      register={register}
                      rules={{
                        required: true,
                      }}
                      errors={errors}
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="drm-pf-information-admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tbody>
              <tr className="drm-pf-information-admin-tbl-header">
                <th>
                  管理番号
                </th>
                <td>
                  <div className="width-40p uk-flex-inline">
                    {informationId && !isDuplicate ? `${informationId.padStart(5, '0')}` : "自動採番"}
                  </div>

                  <div className="width-40p uk-flex-inline">
                    <CheckBox
                      name="is_notify"
                      options={[
                        {
                          value: 'email',
                          label: `お知らせのメールを送信する`,
                        }
                      ]}
                      register={register}
                      rules={{disabled: selectedStatusId !== ENABLE_NOTIFY_STATUS_ID}}
                      errors={errors}
                    />
                  </div>
                </td>
              </tr>
              <tr className="drm-pf-information-admin-tbl-header">
                <th>
                  公開タイトル<span className="error">※</span>
                  {errors.title?.type === "required" && (
                    <span className="error">必須項目です</span>
                  )}
                  {errors.title?.type !== "required" && (
                    <span className="error">{errors.title?.message}</span>
                  )}
                </th>
                <td>
                  <div className="uk-width-1-1 uk-flex">
                    <TextBox
                      name="title"
                      register={register}
                      rules={{
                        required: true,
                        maxLength: { value: 50, message: MESSAGE_NO_E35(1000) },
                      }}
                      errors={errors}
                      placeholder="タイトルを入力してください"
                    />
                  </div>
                </td>
              </tr>
              <tr className="drm-pf-information-admin-tbl-header">
                <th>
                  公開内容<span className="error">※</span>
                  {errors.body?.type === "required" && (
                    <span className="error">必須項目です</span>
                  )}
                  {errors.body?.type !== "required" && (
                    <span className="error">{errors.body?.message}</span>
                  )}
                </th>
                <td>
                  <div className="uk-width-1-1 uk-flex">
                    <TextArea
                      name="body"
                      register={register}
                      rules={{
                        required: true,
                      }}
                      errors={errors}
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>
        {/*<button onClick={()=> {console.log(getValues())}}>debug</button>*/}

        {lastInformation &&
            <div className="uk-text-right uk-margin-small-top uk-margin-small-bottom">
                最終更新ユーザ: {lastInformation?.modified_by} 最終更新:{" "}
              {moment(lastInformation?.modified).format("YYYY-MM-DD HH:mm")}
            </div>
        }
      </div>

      <BlockModal />
      <ConfirmModal
        text="保存時にメールを送信しますがよろしいでしょうか？"
        confirmButtonText="OK"
        isShow={isConfirmNotify}
        onConfirm={() => {
          handleSubmit(onSubmit)();
        }}
        onCancel={() => {
          setIsConfirmNotify(false);
        }}
      />
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="前の画面へ"
        isShow={isShowBackModal}
        onConfirm={() => {
          setBlocker(false);
          setNavigatePath(-1);
        }}
        onCancel={() => {
          setIsShowBackModal(false);
        }}
      />
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={!!errorMessage}
          onConfirm={() => {
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
};

export default DrmPfInformationEdit;
