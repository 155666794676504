import * as Config from '@/config'
import {User} from '@/stores/user'
import * as Attributes from '@/components/Form/Attributes'
import {
  BASIC_MAP_BLOCK_CODE,
  RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT,
  RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_NG,
  RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK
} from "@/config";

export const inputFileAttributeToPostParam = (inputData: any[]) => {
  return inputData;
}
export const attributeDefaultValues = (attributes: any[]): any => {
  const inputAttributes = {} as any;
  for (const attribute of attributes) {
    inputAttributes[attribute.id] = '';
  }
  return inputAttributes;
}

// TODO: 変換系多いので共通化
export const decodeAttributeParams = (attributes: any[], query: any): any => {
  const inputAttributes = {} as any;
  for (const attribute of attributes) {
    const queryValue = query.attributes[attribute.id];
    if (!queryValue) continue;
    if (Config.ATTRIBUTE_CODES.includes(attribute.attribute_kind_code)) {
      if (!queryValue.attribute_value) {
        const arrayValues = [];
        for (const [index, value] of Object.entries(queryValue)) {
          const arrayValue = value as any;
          const option = attribute.attribute_options.find((o: any) => o.code === arrayValue.attribute_code && o.option === arrayValue.attribute_value);
          if (option)
            arrayValues.push(createAttributeOptionValue(option.id, arrayValue.attribute_code, arrayValue.attribute_value));
        }
        inputAttributes[attribute.id] = arrayValues;
      } else {
        const option = attribute.attribute_options.find((o: any) => o.code === queryValue.attribute_code && o.option === queryValue.attribute_value);
        if (option)
          inputAttributes[attribute.id] = createAttributeOptionValue(option.id, queryValue.attribute_code, queryValue.attribute_value);
      }
    } else {
      inputAttributes[attribute.id] = queryValue.attribute_value;
    }
  }
  return inputAttributes;
}

export const inputAttributeOptionValue = (attributeOption: any) => {
  return createAttributeOptionValue(attributeOption.id, attributeOption.code, attributeOption.option);
}
// TODO: 値の持ち方検討→selectにはoption_idで持っておいてget/postの際にcode_valueで検索
export const createAttributeOptionValue = (attribute_option_id: any, attribute_code: any, attribute_value: any) => {
  return attribute_option_id + Config.SPLIT_CODE + attribute_code + Config.SPLIT_CODE + attribute_value;
}

export const inputAttributeToPostParam = (attributes: Array<any>, data: any, dataMap: Map<string, any> | undefined = undefined): any => {
  const inputDataAttributeValues = {} as any;
  const inputDataAttributeTextValues = {} as any;
  if (!data.attributes) return [inputDataAttributeValues];
  for (const attribute of attributes) {
    const inputValue = data.attributes[attribute.id];
    if (!inputValue || inputValue.length === 0)
      continue;
    const dataAttributeValue = dataMap?.get(attribute.id) || undefined;
    if (Config.ATTRIBUTE_CODES.includes(attribute.attribute_kind_code)) {
      const values: any[] = [];
      if (Array.isArray(inputValue)) {
        for (const check of inputValue) {
          const [option_id, code, value] = check.split(Config.SPLIT_CODE);
          const setValue = {attribute_code: code, attribute_value: value} as any;
          if (dataAttributeValue) {
            const existsData = existsDataAttributeValue(code, value, dataAttributeValue);
            if (existsData) setValue["id"] = existsData.id;
          }
          values.push(setValue);
        }
        inputDataAttributeValues[attribute.id] = values;
      } else {
        const [option_id, code, value] = inputValue.split(Config.SPLIT_CODE);
        const setValue = {attribute_code: code, attribute_value: value} as any;
        if (dataAttributeValue) {
          const existsData = existsDataAttributeValue(code, value, dataAttributeValue);
          if (existsData) setValue["id"] = existsData.id;
        }
        inputDataAttributeValues[attribute.id] = setValue;
      }
    } else if (attribute.attribute_kind_code === Config.ATTRIBUTE_KINDS_CODE_TEXT || attribute.attribute_kind_code === Config.ATTRIBUTE_KINDS_CODE_MAP_DRAWING) {
      inputDataAttributeTextValues[attribute.id] = {text: inputValue};
      if (dataAttributeValue) {
        inputDataAttributeTextValues[attribute.id]["id"] = dataAttributeValue.id
      }
      ;
    } else {
      inputDataAttributeValues[attribute.id] = {attribute_value: inputValue};
      if (dataAttributeValue) {
        inputDataAttributeValues[attribute.id]["id"] = dataAttributeValue.id
      }
      ;
    }
  }
  return [inputDataAttributeValues, inputDataAttributeTextValues];
}

export const existsDataAttributeValue = (attribute_code: string, attribute_value: string, dataAttributeValue: any): any => {
  if (Array.isArray(dataAttributeValue)) {
    for (const check of dataAttributeValue) {
      if (attribute_code === check.attribute_code && attribute_value === check.attribute_value)
        return check;
    }
  } else {
    if (attribute_code === dataAttributeValue.attribute_code && attribute_value === dataAttributeValue.attribute_value)
      return dataAttributeValue;
  }
  return undefined;
}

export const convertToKeyMap = (datas: any, key: string): Map<string, any> => {
  const map = new Map();
  const setMap = (data: any) => {
    if (map.has(data[key])) {
      const value = map.get(data[key]);
      if (Array.isArray(value)) {
        value.push(data);
      } else {
        const array = [value, data];
        map.set(data[key], array);
      }
    } else {
      map.set(data[key], data);
    }
  }

  for (const data of datas.data_attribute_text_values) {
    setMap(data);
  }
  for (const data of datas.data_attribute_values) {
    setMap(data);
  }
  return map;
}

export const attributeMap = (attributes: any, key: string): Map<any, any> => {
  const map = new Map();
  for (const attribute of attributes) {
    if (map.has(attribute[key])) {
    } else {
      map.set(attribute[key], attribute);
    }
  }
  return map;
}

export const getAttributeOption = (dataAttributeValue: any, dataMap: Map<any, any>, attributeMap: any, attribute_id: any, area_id: any, block_id: any) => {
  const attribute = attributeMap.get(attribute_id);
  const [parentName, filterName] = Attributes.hasRelation(attribute);
  if (parentName === null || filterName === null) {
    return attribute.attribute_options.find(
      (o: any) => {
        if (Array.isArray(dataAttributeValue)) {
          return dataAttributeValue.some(dataAttr => o.code === dataAttr.attribute_code && o.option === dataAttr.attribute_value)
        } else {
          return o.code === dataAttributeValue.attribute_code && o.option === dataAttributeValue.attribute_value
        }
      });
  }

  let parentOptionId: any = null;
  if (filterName === 'parent_attribute_options_id') {
    const parentAttributeId = parseInt(parentName.substring(11));
    const parentOption = getAttributeOption(dataMap.get(parentAttributeId), dataMap, attributeMap, parentAttributeId, area_id, block_id);
    parentOptionId = parentOption?.id;
  } else if (filterName === 'parent_area_id') {
    parentOptionId = area_id;
  } else if (filterName === 'parent_block_id') {
    parentOptionId = block_id;
  }
  const option = attribute.attribute_options.find(
    (o: any) => {
      const parent = o.attribute_option_parent_values.find((attribute_option_parent_value: any) => {
        return attribute_option_parent_value[filterName] === parentOptionId;
      });
      return parent && o.code === dataAttributeValue.attribute_code && o.option === dataAttributeValue.attribute_value
    });
  return option;
}

export const getNestedValue = (data: any): any => {
  if (typeof data === "object")
    return getNestedValue(data[Object.keys(data)[0]]);
  else
    return data;
}

export const getTabIndex = (tabs: any[], tabId: string | undefined) => {
  return tabId ? tabs.findIndex((tab: any) => parseInt(tabId) === tab.id) : 0;
}

export const attributesNewUrl = (projectCategoryId: any) => {
  if (projectCategoryId)
    return `/progress/${projectCategoryId}/new/attributes`;
  else
    return "";
}
export const attributesEditUrl = (projectCategoryId: any, dataId: any, tabId: any = undefined) => {
  if (projectCategoryId && dataId && tabId)
    return `/progress/${projectCategoryId}/${dataId}/attributes/${tabId}/edit`;
  else if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/attributes/edit`;
  else
    return "";
}
export const attributesDetailUrl = (projectCategoryId: any, dataId: any, tabId: any = undefined) => {
  if (projectCategoryId && dataId && tabId)
    return `/progress/${projectCategoryId}/${dataId}/attributes/${tabId}`;
  else if (dataId)
    return `/progress/${projectCategoryId}/${dataId}/attributes`;
  else
    return "";
}
export const attributesCopyUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/attributes/copy`;
  else
    return "";
}
export const applyAreaListUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/apply_areas`;
  else
    return "";
}
export const applyAreaListEditUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/apply_areas/edit`;
  else
    return "";
}
export const filesNewUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/files/new`;
  else
    return "";
}
export const filesEditUrl = (projectCategoryId: any, dataId: any, fileId: any) => {
  if (projectCategoryId && dataId && fileId)
    return `/progress/${projectCategoryId}/${dataId}/files/edit/${fileId}`;
  else
    return "";
}
export const filesListUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/files`;
  else
    return "";
}
export const certificationListUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/certification`;
  else
    return "";
}
export const ticketUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/ticket`;
  else
    return "";
}
export const publicDetailUrl = (projectCategoryId: any, dataId: any, faqId: any = undefined) => {
  if (projectCategoryId && dataId && faqId)
    return `/progress/${projectCategoryId}/${dataId}/public/${faqId}`;
  else if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/public`;
  else
    return "";
}
export const publicEditUrl = (projectCategoryId: any, dataId: any, faqId: any = undefined) => {
  if (projectCategoryId && dataId && faqId)
    return `/progress/${projectCategoryId}/${dataId}/public/${faqId}/edit`;
  else if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/public/new`;
  return "";
}
export const approveDetailUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/approve`;
  else
    return "";
}
export const approveEditUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/approve/edit`;
  else
    return "";
}
export const formsUrl = (projectCategoryId: any, dataId: any) => {
  if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/forms`;
  else
    return "";
}
export const formsPdfUrl = (projectCategoryId: any, dataId: any, applyPdf: any) => {
  if (projectCategoryId && dataId && applyPdf)
    return `/progress/${projectCategoryId}/${dataId}/forms/${applyPdf}`;
  else if (projectCategoryId && dataId)
    return `/progress/${projectCategoryId}/${dataId}/forms`;
  else
    return "";
}

export const formatPrice = (number: number) => {
  const formatter = new Intl.NumberFormat('ja-JP');
  return formatter.format(number);
}

/* DBから取得したエリア一覧（ツリー含む）からエリアを探す */
export const getArea = (areas: any[], id: number): any | null => {
  for (const area of areas) {
    const result = getAreaById(area, id);
    if (result) {
      return result;
    }
  }
  return null;
}

/* ツリーのエリアデータから子エリアを探す */
export const getAreaById = (area: any, id: number): any | null => {
  if (area.id == id) {
    return area;
  }
  if (area.children) {
    for (const child of area.children) {
      const result = getAreaById(child, id);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

const findNodeById = (tree: any, targetId: number, path = []): any | null => {
  // Base case: if the current node is the target node, return it along with the path
  const object = (({['children']: _, ...rest}) => rest)(tree);
  if (tree.id === targetId) {
    return {node: tree, path: path.concat(object)};
  }

  // Recursive case: search in the children of the current node
  if (tree.children) {
    for (const child of tree.children) {
      const result = findNodeById(child, targetId, path.concat(object));
      if (result) {
        return result;
      }
    }
  }

  // If the target ID is not found in the current node or its children, return null
  return null;
}

export const getTreeDepth = (node: any, depth = 0): number => {
  if (!node.children) {
    return depth;
  }
  return Math.max(...node.children.map((child: any) => getTreeDepth(child, depth + 1)));
}

export const getFullAreas = (salesProductId: number, areas: any[]) => {
  for (const area of areas) {
    const result = findNodeById(area, salesProductId);
    if (result) {
      return result.path;
    }
  }
  return [];
}

export const getLocked = (data: any, user: any) => {
  if (!data) return false;
  if (!user) return false;
  if (data.modified_by === user.id) return false;
  const now = new Date();
  const start = new Date(data.edit_start);
  const diff = (now.getTime() - start.getTime()) / 60000;
  return diff < 120;
}

// 戻り値のtab_idを使用
export const canShowAttribute = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return true;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB
          && user_restriction.category_restriction_value > 0
      });
    default:
      return false;
  }
}

export const canAttributeTab = (projectCategoryId: any, tabId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_EDIT.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        if (user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB
          && user_restriction.category_restriction_value > 0) {
        }
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB
          && user_restriction.category_restriction_value > 0
          && user_restriction.tab_id == tabId
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB_HIDE.value;
  }
}

export const canFile = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_FILE_EDIT.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        if (user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_FILE) {
        }
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_FILE
          && user_restriction.category_restriction_value > 0
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_FILE_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_FILE_HIDE.value;
  }
}

export const canBilling = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return true;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_code === Config.RESTRICTION_BILLING_LIST
          && user_restriction.category_restriction_value === 1
      }) !== undefined;
    default:
      return false;
  }
}

export const canApprove = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_APPROVE_ADMIN.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_APPROVE
          && user_restriction.category_restriction_value > 0
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_APPROVE_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_APPROVE_HIDE.value;
  }
}

export const canApplyArea = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_APPLY_AREA_EDIT.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_APPLY_AREA
          && user_restriction.category_restriction_value > 0
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_APPLY_AREA_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_APPLY_AREA_HIDE.value;
  }
}

export const canForm = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_FORM_EDIT.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_FORM
          && user_restriction.category_restriction_value > 0
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_FORM_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_FORM_HIDE.value;
  }
}

export const canTicket = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_TICKET_EDIT.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_TICKET
          && user_restriction.category_restriction_value > 0
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_TICKET_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_TICKET_HIDE.value;
  }
}
export const canPublic = (projectCategoryId: any, user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_PUBLIC_EDIT.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        if (user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PUBLIC) {
          console.log("user_restriction")
          console.log(user_restriction)
        }
        return user_restriction.project_category_id === projectCategoryId
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PUBLIC
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_PUBLIC_HIDE.value;
    default:
      return Config.RESTRICTION_HISTORY_PUBLIC_HIDE.value;
  }
}
export const canUserManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_USER_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_USER
          && user_restriction.category_restriction_value > 0
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_USER_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_USER_NG.value;
  }
}
export const canDataExportManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_DATA_EXPORT_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_value > 0
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_DATA_EXPORT
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_DATA_EXPORT_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_DATA_EXPORT_NG.value;
  }
}
export const canDataImportManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_DATA_IMPORT_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_value > 0
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_DATA_IMPORT
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_DATA_IMPORT_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_DATA_IMPORT_NG.value;
  }
}

export const canInformationManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_MANAGE_INFORMATION_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_value > 0
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_MANAGE_INFORMATION
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_MANAGE_INFORMATION_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_MANAGE_INFORMATION_NG.value;
  }
}

export const canDocumentManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_value > 0
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_NG.value;
  }
}
export const canBasicMapManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_value > 0
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_NG.value;
  }
}

export const canMaterialCollectionListManagement = (user?: User) => {
  switch (user?.roll) {
    case Config.PERMISSION_ROLL_SYSADMIN:
      return Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_OK.value;
    case Config.PERMISSION_ROLL_ADMIN:
    case Config.PERMISSION_ROLL_USER:
      return user.user_restrictions.find((user_restriction) => {
        return user_restriction.category_restriction_value > 0
          && user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT
      })?.category_restriction_value || Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_NG.value;
    default:
      return Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_NG.value;
  }
}

export function byteConverter(bytes: number, unit: string = "B") {
  const units: { [key: string]: number } = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024
  };
  return bytes / units[unit];
}

export const isMapButtonActive = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_GEO_JSON
    || setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_P_ID
  );
}
export const isMarkDown = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_MARKDOWN
  );
}
export const isVersionNumber = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_VERSION_NUMBER
  );
}
export const isPId = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_P_ID
  );
}
export const isSecondaryMesh = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_IS_SECONDARY_MESH
  );
}
export const isSecondaryMeshName = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting_value: any) =>
    setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_IS_SECONDARY_MESH_NAME
  );
}
export const isNotEdit = (attribute: any, isType: Config.Data) => {
  return (attribute.attribute_setting_values.some((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_NOT_EDIT)
  && isType === Config.Data.AttributeEdit)
  || (attribute.attribute_setting_values.some((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_IS_AUTO_INCREMENT));
}

export const isMapDrawing = (attribute: any) => {
  return attribute.attribute_kind_code === Config.ATTRIBUTE_KINDS_CODE_MAP_DRAWING;
}

export const parseCoordinate = (str: string) => {
  const regex = /POINT\(([-+]?[0-9]*\.?[0-9]+) ([-+]?[0-9]*\.?[0-9]+)\)/;
  const match = str.match(regex);

  if (match && match.length === 3) {
    const x = match[1];
    const y = match[2];

    return {x, y};
  }

  return null;
}

export const getCurrentTimestamp = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}
export const generateRandom8DigitNumber = (): string => {
  const min = 0;
  const max = 99999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber.toString().padStart(8, '0');
}

export const parseGeoJson = (json: any) => {
  if (!json.features) return {points: [], polygons: [], lines: []};
  let points: any[] = [];
  let polygons: any[] = [];
  let lines: any[] = [];
  json.features.forEach((feature: any) => {
    const {geometry} = feature;
    if (geometry.type === 'Point') {
      const {coordinates} = geometry;
      points.push({x: coordinates[1], y: coordinates[0]});
    } else if (geometry.type === 'Polygon') {
      polygons.push(geometry);
    } else if (geometry.type === 'LineString') {
      lines.push(geometry);
    }
  });
  return {points, polygons, lines};
}

export const decisionStatus = (data: any) => {
  const approvalRequest = data.data_approval_requests[0];
  const bool1 = approvalRequest?.approval_request_status_id === 2;
  const bool2 = approvalRequest?.data_approval_decision_users.some((user: any) =>
    user.approval_decision_status_id === 2
  );
  const bool3 = approvalRequest?.data_approval_decision_users.some((user: any) =>
    user.approval_decision_status_id === 1 && user.is_decision_user === 1
  );
  const bool = bool1 && bool2 && !bool3;

  return bool ? '承認済み' : '未承認';
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('ja-JP', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/\//g, '/');
}

//文字列から数字を切り出す
export const extractNumber = (str: string): number | null => {
  const match = str.match(/\d+/);
  return match ? parseInt(match[0]) : null;
}

type VersionNumberOption = {
  id: number;
  attribute_id: number;
  code: string;
  option: string;
  attribute_option_parent_values: any;
}
//版番号選択肢のフィルター&並べ替え
export const validVersionNumber = (versionNumber: VersionNumberOption[]) => {
  let resultVersion = versionNumber;
  resultVersion = versionNumber.filter((version) => {
    return isStartVersionNumber(version.code);
  })
  resultVersion = resultVersion.sort((a, b) =>
    parseInt(b.code) - parseInt(a.code)
  )
  return resultVersion;
}

//版番号の表示開始判定
export const isStartVersionNumber = (code: string) => {
  const currentDate = new Date();

  const yearStr = code.slice(0, 2);
  const monthStr = code.slice(2);
  const year = parseInt(yearStr) + 1988;
  const month = parseInt(monthStr);
  const startDate = new Date(year, month - 3, 1);

  return startDate <= currentDate
}

//基盤地図用のダミーデータをフィルタリング
type Block = {
  id: number;
  area_id: number;
  code: string;
  name: string;
}
export const filteredBlocks = (blocks: Block[], selectedProjectCategory: any) => {
  const isHistory = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_HISTORY;
  return isHistory ? blocks.filter((block) => block.code !== BASIC_MAP_BLOCK_CODE) : blocks;
}

// 供用開始　供用予定管理リストと資料収集リスト用
export const isServiceStartInfo = (selectedProjectCategory: any) => {
  const isServiceStartInfo = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_SERVICE_START_INFO;
  return isServiceStartInfo;
}
export const isManage = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting: any) => setting.attribute_setting_code == Config.ATTRIBUTE_SETTINGS_CODE_MANAGE_LIST_DISPLAY_ORDER)
}
export const isCollect = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting: any) => setting.attribute_setting_code == Config.ATTRIBUTE_SETTINGS_CODE_COLLECT_LIST_DISPLAY_ORDER)
}
// 自動採番かどうかの判定用
export const isAutoIncriment = (attribute: any) => {
  return attribute.attribute_setting_values.some((setting: any) => setting.attribute_setting_code === Config.ATTRIBUTE_SETTINGS_CODE_IS_AUTO_INCREMENT)
}
