import {useFormContext, useWatch} from "react-hook-form";
import {basicMapStore} from "@/stores/basicMap";
import {Attribute} from "@/components/ProgressList/types";
import * as Common from "@/utils/common";
import {useEffect} from "react";

interface SecondaryMeshAutoFillProps {
  attributes: Attribute[];
}

export const SecondaryMeshAutoFill = ({attributes}: SecondaryMeshAutoFillProps) => {

  const {control, setValue} = useFormContext();
  const {dicCities} = basicMapStore();

  const selectedArea = useWatch({
    control,
    name: "area_id",
  });

  const secondaryMeshNumberId = attributes.find(attr => Common.isSecondaryMesh(attr))?.id;
  const watchSecondaryMeshNumber = useWatch({
    control,
    name: `attributes.${secondaryMeshNumberId}`
  });

  const secondaryMeshNumberNameId = attributes.find(attr => Common.isSecondaryMeshName(attr))?.id;

  useEffect(() => {
    const secondaryMeshName = dicCities.find(city => city.code === watchSecondaryMeshNumber)?.name;
    setValue(`attributes.${secondaryMeshNumberNameId}`, secondaryMeshName ?? undefined);
  }, [watchSecondaryMeshNumber])

  useEffect(() => {
    setValue(`attributes.${secondaryMeshNumberId}`, undefined);
    setValue(`attributes.${secondaryMeshNumberNameId}`, undefined);
  }, [selectedArea])

  return null;
}