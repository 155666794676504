import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import SubHeader from "@/components/SubHeader";

import {axios} from "@/lib/axios";
import * as Config from "@/config";
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";

import {AllBills, AllLogs, Fees, UsageHistorySearchState} from "@/containers/customer/drmPf/types";
import qs from 'qs';
import {BillData} from "@/components/Customer/UsageHistory/BillData";
import {ApiGroups} from "@/components/Customer/UsageHistory/ApiGroups";
import {DisplayLogData} from "@/components/Customer/UsageHistory/DisplayLogData";
import {SearchSection} from "@/components/Customer/UsageHistory/SearchSection";
import {Tooltip} from "react-tooltip";
import {useDrmPfApiUsages} from "@/containers/customer/usageHistory/useDrmPfApiUsages";

interface Option {
  value: string,
  label: string,
}

export default function UsageHistory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const breadCrumbs = [
    {title: '顧客一覧', path: '/customer'},
    {title: 'API利用履歴'}
  ];

  const {downloadExcel} = useDrmPfApiUsages();
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);

  //マスタ系
  const [customerTypes, setCustomerTypes] = useState<Option[]>([]);
  const [customerGroupTypes, setCustomerGroupTypes] = useState<Option[]>([]);
  const [customerPaymentTypes, setCustomerPaymentTypes] = useState<Option[]>([]);

  // URLパラメータから検索条件を取得し、状態に反映する
  const initParams = {
    customer_number: searchParams.get('customer_number') || undefined,
    group_name: searchParams.get('group_name') || undefined,
    last_name: searchParams.get('last_name') || undefined,
    first_name: searchParams.get('first_name') || undefined,
    customer_group_type_id: searchParams.get('customer_group_type_id') || undefined,
    customer_type_id: searchParams.getAll('customer_type_id') || undefined,
    customer_payment_type_id: searchParams.getAll('customer_payment_type_id') || undefined,
    from_date: searchParams.get('from_date') || undefined,
    to_date: searchParams.get('to_date') || undefined,
    page_size: searchParams.get('page_size') || '100',
    page: searchParams.get('page') || '1'
  }

  // 検索項目
  const [searchState, setSearchState] = useState<UsageHistorySearchState>(initParams);

  // 検索結果
  const [logs, setLogs] = useState<AllLogs[]>([]);
  const [fees, setFees] = useState<Fees[]>([]);
  const [bills, setBills] = useState<AllBills[]>([]);
  const [paging, setPaging] = useState<any>(undefined);

  useEffect(() => {
    axios.get('/api/v1/customers/getCustomerTypes')
      .then(result => {
        setCustomerTypes(result.data.customerTypes);
        setCustomerGroupTypes(result.data.customerGroupTypes);
        setCustomerPaymentTypes(result.data.customerPaymentTypes);
      }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    });
    doSearch(searchState);
  }, [])

  // 検索処理
  function doSearch(params: UsageHistorySearchState) {
    setLoading(true);
    axios.get('/api/v1/customers/getUsageHistory', {
      params: params
    }).then(result => {
      setSearchParams(qs.stringify(params));
      setPaging(result.data.paging);
      setLogs(result.data.displayLogData);
      setBills(result.data.billData);
      setFees(result.data.apiGroup);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  //表示数変更時処理
  const onSelectedPageSize = (pageSize: string) => {
    const state = {
      ...searchState,
      page_size: pageSize
    }
    doSearch(state);
  }

  //ページ数移動時処理
  const onPageChange = (page: string) => {
    const state = {
      ...searchState,
      page: page
    }
    doSearch(state);
  }

  const onSearch = () => {
    doSearch(searchState);
  }

  const onClear = () => {
    setSearchState(prevState => ({
      page_size: prevState.page_size,
      page: prevState.page,
      customer_number: '',
      group_name: '',
      last_name: '',
      first_name: '',
      customer_group_type_id: '',
      customer_type_id: undefined,
      customer_payment_type_id: undefined,
      from_date: '',
      to_date: ''
    }));
  }

  const onExcel = () => {
    const params = (({page, page_size, ...rest}) => rest)(searchState);
    setLoading(true);
    const postParams: { [key: string]: any; } = {
      'params': params,
    };

    axios.post('/api/v1/customers/exportUsageHistory', postParams, {
      responseType: 'blob',
    })
      .then(response => {
        downloadExcel(response);
      })
      .catch(error => {
        setMessage(Config.MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Tooltip id="customer-tooltip" style={{zIndex: 3}}/>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="API利用履歴"/>
        <ul className="uk-tab uk-margin-small-top">
        </ul>
      </div>
      <div className="container uk-margin-samll-bottom">
        <div className="uk-text-right customer-attribute-tab">
          <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onExcel}>Excel出力</button>
        </div>
      </div>
      <div className="container">
        <div className="info-tbl_wrap uk-margin-small-top">

          <SearchSection
            searchState={searchState}
            setSearchState={setSearchState}
            onSearch={onSearch}
            onClear={onClear}
            customerTypes={customerTypes}
            customerGroupTypes={customerGroupTypes}
            customerPaymentTypes={customerPaymentTypes}/>

          <BillData bills={bills}/>

          <ApiGroups fees={fees}/>

          <DisplayLogData
            logs={logs}
            paging={paging}
            onPageChange={onPageChange}
            onSelectedPageSize={onSelectedPageSize}/>

        </div>
      </div>
    </div>
  );
}