import {SharedTableHeadAttributes, SharedTableHeadTabNames} from "@/components/ProgressList/results/SharedTableHead";
import React from "react";
import {SharedTableBodyAttributes, SharedTableBodyTabs} from "@/components/ProgressList/results/SharedTableBody";

export const FaqTableHead = () => {
  return (
    <>
      <SharedTableHeadAttributes/>
      <SharedTableHeadTabNames/>
      <th className="uk-padding-remove-horizontal">
        <div className="border-box"/>
        <div className="uk-text-center">公開設定</div>
      </th>
    </>
  )
}

interface FaqTableBodyProps {
  data: any;
  currentFaqStatuses: (data_id: number) => any;
  checked: boolean;
}

export const FaqTableBody = (props: FaqTableBodyProps) => {
  return (
    <>
      <SharedTableBodyAttributes data={props.data} checked={props.checked}/>
      <SharedTableBodyTabs data={props.data}/>
      {/* 公開タブのステータスリスト */}
      <td data-tooltip-id="progress-tooltip">
        <div className="border-box"/>
        {props.currentFaqStatuses(props.data.id)?.statuses?.map((item: any) => {
          return (
            <div>{item.status}</div>
          )
        })}
      </td>

    </>
  )
}