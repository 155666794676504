import React, { useCallback, useEffect, useState } from "react";
import { useLoadingStore } from "@/stores/loading";
import { importCsv } from "@/api/data";
import {
  CSV_POSTAL_CODE_BUSINESS_IMPORT_TYPE,
  CSV_POSTAL_CODE_IMPORT_TYPE,
  ERROR_MESSAGE_E23,
  MESSAGE_NO_E39,
  SUCCESS_MESSAGE_E24,
} from "@/config";
import ConfirmModal from "@/components/ConfirmModal";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface error {
  file?: string;
  businessFile?: string;
}

export default function PostalCodeImport() {
  const setLoading = useLoadingStore((state) => state.setLoading);

  const [isShowModal, setIsShowModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [businessFile, setBusinessFile] = useState<File | null>(null);
  const [error, setError] = useState<error | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  const onDrop = useCallback((files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  }, []);
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const onBusinessFileDrop = useCallback((files: File[]) => {
    if (files.length > 0) {
      setBusinessFile(files[0]);
    }
  }, []);
  const dropzoneBusinessFileOptions: DropzoneOptions = {
    onDrop: onBusinessFileDrop,
    accept: { "text/csv": [".csv"] },
    multiple: false,
  };
  const {
    getRootProps: getBusinessFileRootProps,
    getInputProps: getBusinessFileInputProps,
  } = useDropzone(dropzoneBusinessFileOptions);

  const handleFileImportChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleBusinessFileImportChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      setBusinessFile(e.target.files[0]);
    }
  };

  const onClickClear = () => {
    setBusinessFile(null);
    setFile(null);
    setResultMessage(null);
  };

  const onClickImport = async (e: any) => {
    setResultMessage(null);
    let messages: any = {};
    if (!file) {
      messages["file"] = "必須項目です。";
    }
    if (!businessFile) {
      messages["businessFile"] = "必須項目です。";
    }
    if (!businessFile || !file) {
      e.preventDefault();
      setError(messages);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const businessFormData = new FormData();
    businessFormData.append("file", businessFile);

    setLoading(true);
    await Promise.all([
      importCsv(formData, CSV_POSTAL_CODE_IMPORT_TYPE),
      importCsv(businessFormData, CSV_POSTAL_CODE_BUSINESS_IMPORT_TYPE),
    ])
      .then((result) => {
        // console.log(result);
        setResultMessage(SUCCESS_MESSAGE_E24);
      })
      .catch((error) => {
        // console.log(error);
        if (!error.response) {
          setResultMessage(MESSAGE_NO_E39);
          return;
        }
        //エラーファイルダウンロード処理
        const contentType = error.response.headers["content-type"];
        if (
          contentType === "text/csv; charset=UTF-8" &&
          error.response.status === 400
        ) {
          const blob = new Blob([error.response.data], { type: contentType });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = businessFile.name.replace(`.csv`, `(error).csv`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(downloadUrl);
        }

        if (error.response.data["message"]) {
          setResultMessage(error.response.data.message);
        } else {
          setResultMessage(ERROR_MESSAGE_E23);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    resultMessage && setIsShowModal(true);
  }, [resultMessage]);

  return (
    <div>
      <div className="precaution">※は必須です</div>
      <form>
        <div className="admin-tbl uk-margin-small-top">
          <table className="uk-width-1-1">
            <tr>
              <td>
                住所の郵便番号
                <span className="error">※</span>
                {error?.file && (
                  <span className="precaution">{error.file}</span>
                )}
              </td>
              <td>
                <div {...getRootProps()} className="file-select uk-form-custom">
                  <input
                    type="file"
                    {...getInputProps()}
                    onChange={handleFileImportChange}
                  />
                  <button
                    className="uk-button--m uk-button-cancel"
                    type="button"
                  >
                    ファイル選択
                  </button>
                  <div>{file?.name}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                事業所の個別郵便番号
                <span className="error">※</span>
                {error?.businessFile && (
                  <span className="precaution">{error.businessFile}</span>
                )}
              </td>
              <td>
                <div
                  {...getBusinessFileRootProps()}
                  className="file-select uk-form-custom"
                >
                  <input
                    type="file"
                    {...getBusinessFileInputProps()}
                    onChange={handleBusinessFileImportChange}
                  />
                  <button
                    className="uk-button--m uk-button-cancel"
                    type="button"
                  >
                    ファイル選択
                  </button>
                  <div>{businessFile?.name}</div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </form>
      <div className="uk-flex uk-flex-center uk-margin-top">
        <button
          className="uk-button--m uk-button-cancel"
          onClick={onClickClear}
        >
          クリア
        </button>
        <button
          className="uk-button--m uk-button-refer uk-margin-left"
          onClick={onClickImport}
        >
          インポート
        </button>
      </div>
      {resultMessage && (
        <ConfirmModal
          text={resultMessage}
          confirmButtonText="OK"
          isShow={isShowModal}
          onConfirm={() => {
            setIsShowModal(false);
            setResultMessage(null);
          }}
        />
      )}
    </div>
  );
}
