import * as Config from '@/config'
import React, { createRef, RefObject, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataProps } from "@/containers/data/Data";
import * as Common from "@/utils/common";
import { deleteFaq as deleteFaqInformation, getFaqInformation } from "@/api/faqs";
import { PostFaqDataMethods, PublicEditItem } from "@/containers/data/Public/PublicEditItem";
import PublicDetailItem from "@/containers/data/Public/PublicDetailItem";
import { useLoadingStore } from "@/stores/loading";
import ConfirmModal from "@/components/ConfirmModal";
import { MESSAGE_NO_E09, MESSAGE_NO_E11, MESSAGE_NO_E37, MESSAGE_NO_E39 } from "@/config";
import BlockModal from "@/components/BlockModal";
import { useBlockerStore } from "@/stores/blocker";
import { useUserStore } from "@/stores/user";
import { useProjectStore } from "@/stores/project";

export interface Faq {
  id?: number
  faq_status_id?: number
  faq_types?: { id: number, checked: boolean }[]
  faq_kinds?: { id: number, checked: boolean }[]
  title?: string
  question?: string
  answer?: string
  remarks?: string
  modified?: string
  modified_by?: string
}

export interface TicketComment {
  id: number
  created: string
  created_group_by: string
  created_user_by: string
  is_inquiry_user: boolean
  comment: string
  attached_files: AttachedFile[]
}

export interface AttachedFile {
  id?: number
  original_filename: string
  filepath: string
}

export interface Option {
  id: number
  name: string
}

export default function Public( props: DataProps ) {
  const user = useUserStore(state => state.user);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const setLoading = useLoadingStore(state => state.setLoading)
  const [isBlocker, setBlocker] = useBlockerStore(state => [state.isBlocker, state.setBlocker]);
  const navigate = useNavigate();
  const params = useParams();
  const pathname = useLocation().pathname
  const isNewPath = pathname.includes("new")
  const canPublic = Common.canPublic(selectedProjectCategory?.id, user)

  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [faqStatuses, setFaqStatuses] = useState<Option[]>([])
  const [faqTypes, setFaqTypes] = useState<Option[]>([])
  const [faqKinds, setFaqKinds] = useState<Option[]>([])
  const [ticketComments, setTicketComments] = useState<TicketComment[]>([])

  const [currentFaqId, setCurrentFaqId] = useState<string | number | undefined>(
    params.faqId
  )
  const currentFaqIndex = isNewPath ? faqs.length - 1 : Common.getTabIndex(faqs, params.faqId);
  const [editItemRefs, setEditItemRefs] = useState<RefObject<PostFaqDataMethods>[]>([]);
  const [modalMessage, setModalMessage] = useState<{
    type: string,
    message: string,
    faqId?: string | number
  } | null>(null)

  const onClickEdit = () => {
    setBlocker(Config.isDataEdit(props.isType));
    navigate(Common.publicEditUrl(params.projectCategoryId, params.dataId, currentFaqId));
  };

  const onClickSave = async () => {
    await setBlocker(false);
    const currentRef = editItemRefs[currentFaqIndex];
    if (currentRef && currentRef.current) {
      const result = await currentRef.current.submit();
      if (result) {
        const res = await getFaqs();
        if (res) {
          console.log(currentFaqId)
          const lastIndex = res.length - 1;
          console.log(res)
          currentFaqId ?
            navigate(Common.publicDetailUrl(params.projectCategoryId, params.dataId, currentFaqId)) :
            navigate(Common.publicDetailUrl(params.projectCategoryId, params.dataId, res[lastIndex].id))
        }
      }
    }
  };

  const onClickDelete = () => {
    setBlocker(false);
    setModalMessage({ type: "delete", message: MESSAGE_NO_E11, faqId: currentFaqId })
  }

  const onClickCancel = () => {
    setBlocker(false);
    setModalMessage({ type: "cancel", message: MESSAGE_NO_E09, faqId: currentFaqId })
  }

  const onClickAdd = () => {
    const newFaqOption = ( faqOptions: Option[] ) => {
      return faqOptions.map(option => {
        return { id: option.id, checked: false }
      })
    }
    const newFaq = {
      faq_kinds: newFaqOption(faqKinds),
      faq_types: newFaqOption(faqTypes)
    }
    setFaqs([...faqs, newFaq])
    navigate(Common.publicEditUrl(params.projectCategoryId, params.dataId))
  };

//モーダルok時の処理
  const onConfirm = async () => {
    switch (modalMessage?.type) {
      case "delete":
        const destinationFaqId = currentFaqIndex === 0 ? faqs[currentFaqIndex + 1].id : faqs[currentFaqIndex - 1].id
        if (isNewPath) {
          navigate(Common.publicDetailUrl(params.projectCategoryId, params.dataId, destinationFaqId))
          getFaqs()
        } else {
          if (modalMessage?.faqId) {
            deleteFaq(modalMessage?.faqId)
            getFaqs()
            navigate(Common.publicDetailUrl(params.projectCategoryId, params.dataId, destinationFaqId))
          }
        }
        break;
      case "cancel":
        navigate(Common.publicDetailUrl(params.projectCategoryId, params.dataId, currentFaqId));
        break;
      case "server":
        await setBlocker(false)
        navigate(Common.publicDetailUrl(params.projectCategoryId, params.dataId, currentFaqId));
    }
    setModalMessage(null);
  }

  const onCancel = () => {
    switch (modalMessage?.type) {
      case "delete":
      case "cancel":
        setModalMessage(null);
        break;
      default:
        return undefined
    }
  }

  const tabLink = ( faqId: string | number | undefined ) => {
    return Common.publicDetailUrl(params.projectCategoryId, params.dataId, faqId);
  }
  const onClickTab = async ( faqId: number | undefined ) => {
    await navigate(tabLink(faqId))
    setCurrentFaqId(faqId)
  }

  const getFaqs = async () => {
    setLoading(true)
    return await getFaqInformation(Number(params.dataId)).then(result => {
      // console.log(result.data)
      setFaqTypes(result.data.faq_types)
      setFaqKinds(result.data.faq_kinds)
      setTicketComments(result.data.data_ticket_comments)
      setFaqs(result.data.data_faqs)
      setFaqStatuses(result.data.faq_statuses)
      if (!currentFaqId && result.data.data_faqs[0]) {
        setCurrentFaqId(result.data.data_faqs[0].id)
      }
      return result.data.data_faqs;
    }).catch(error => {
      if (!error.response) {
        setModalMessage({ type: "server", message: MESSAGE_NO_E39 })
      }
      return false;
    }).finally(() => {
      setLoading(false)
    })
  }

  const deleteFaq = ( faqId: number | string ) => {
    setLoading(true)
    deleteFaqInformation(Number(faqId)).then(result => {
    }).catch(error => {
      setLoading(false)
      if (!error.response) {
        setModalMessage({ type: "server", message: MESSAGE_NO_E37 })
      }
    })
  }


  useEffect(() => {
    setBlocker(Config.isDataEdit(props.isType))
    if (canPublic < Config.RESTRICTION_HISTORY_PUBLIC_VIEW.value
      || ((props.isType === Config.Data.PublicEdit) && canPublic < Config.RESTRICTION_HISTORY_PUBLIC_EDIT.value)) {
      console.log("Public")
      navigate('/progress');
    }
    setCurrentFaqId(params.faqId)
    if (!isNewPath) {
      getFaqs()
    }
  }, [pathname])

  useEffect(() => {
    // Refs配列をFAQの数に基づいて初期化または更新
    setEditItemRefs(elRefs => (
      Array(faqs.length).fill(null).map(( _, i ) => elRefs[i] || createRef())
    ));
  }, [faqs.length]);

  return (
    <div>
      <div className="container">
        <ul className="uk-tab public-tab">
          {faqs.map(( faq, index ) => {
            return (
              <li
                key={`faq_${faq.id}`} onClick={() => onClickTab(faq.id)}
                className={`${index === currentFaqIndex ? "uk-active" : ""}`}
              >
                <Link to={tabLink(faq.id)}>公開{index + 1}</Link>
              </li>
            )
          })}
          <div className="right">
            {props.isType === Config.Data.PublicEdit ?
              <div className="uk-flex">
                <button className="uk-button--m uk-button-danger uk-margin-left" onClick={onClickDelete}>削除</button>
                <button className="uk-button--m uk-button-cancel uk-margin-left" onClick={onClickCancel}>キャンセル</button>
                <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
              </div>
              :
              <div className="uk-flex">
                <button className="uk-button--l uk-button-refer uk-margin-left" onClick={onClickAdd}>公開タブの追加
                </button>
                {faqs.length > 0 &&
                    <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickEdit}>編集</button>
                }
              </div>
            }
          </div>
        </ul>
        {faqs[currentFaqIndex] &&
            <>
              {props.isType === Config.Data.PublicEdit ?
                <PublicEditItem
                  key={`faq_${faqs[currentFaqIndex].id}`}
                  faq={faqs[currentFaqIndex]}
                  faqStatuses={faqStatuses}
                  faqKinds={faqKinds}
                  faqTypes={faqTypes}
                  ticketComments={ticketComments}
                  ref={editItemRefs[currentFaqIndex]}
                  setModalMessage={setModalMessage}
                />
                :
                <PublicDetailItem
                  key={`faq_${faqs[currentFaqIndex].id}`}
                  faq={faqs[currentFaqIndex]}
                  faqStatuses={faqStatuses}
                  faqKinds={faqKinds}
                  faqTypes={faqTypes}
                  ticketComments={ticketComments}
                />
              }
            </>
        }
      </div>
      <BlockModal/>
      <ConfirmModal
        text={modalMessage?.message ?? ""}
        confirmButtonText="OK"
        isShow={!!modalMessage?.message}
        onConfirm={onConfirm}
        onCancel={modalMessage?.type === "server" ? undefined : onCancel}
      />
    </div>
  );
}
