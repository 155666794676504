import Markdown, {Components} from "react-markdown";
import rehypeRaw from "rehype-raw";
import React from "react";
import remarkGfm from "remark-gfm";

export const CustomMarkdown = ({content}: { content?: string }) => {
  const renderers: Components = {
    p: ({children}) => <p style={{margin: 0, whiteSpace: "pre-wrap"}}>{children}</p>,
    table: props => <table style={{backgroundColor: "white"}}>{props.children}</table>,
    th: props => <th style={{backgroundColor: '#efefef'}}>{props.children}</th>,
    td: props => <td style={{backgroundColor: "white"}}>{props.children}</td>
  }
// 改行調整_複数回改行を行いたいが全てbrタグにしてしまうとマークダウン本来のテーブルなどの表示が反映されないため
  const preprocessContent = (text?: string): string => {
    if (!text) {
      return '';
    }
    return text.replace(/\n{2,}/g, (match) => {
      const brCount = Math.max(0, match.length - 1);
      return '\n\n' + '<br>'.repeat(brCount);
    });
  };

  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={renderers}>
      {preprocessContent(content)}
    </Markdown>
  )
}