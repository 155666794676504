import {axios} from '@/lib/axios';

//インポートチェックリスト
export const getDataReportImport = () => {
  return axios.post('/api/v1/MaterialCollectionList/getDataReportImport')
}
//インポートチェック
export const checkMaterialCollectionListImportFile = (import_file: FormData) => {
  return axios.post('/api/v1/MaterialCollectionList/checkImportMaterialCollectionList', import_file, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
//インポート
export const importMaterialCollectionListFile = (tmpPath: string | null, name?: string) => {
  return axios.post('/api/v1/MaterialCollectionList/importMaterialCollectionList', {tmpPath: tmpPath, name: name})
}

