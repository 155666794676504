import * as Config from "@/config";
import {postDataBasicMapCertification} from "@/api/basicMap";
import {ErrorOption} from "react-hook-form";
import * as Common from "@/utils/common";
import {BasicMapCity, PostDataBasicMapCity} from "@/components/Attributes/BasicMap/types";

export function useBasicMap() {

  //更新対象市町村かどうか判定
  const isUpdatedMunicipalities = (attribute: any) => {
    return attribute.column_name === Config.ATTRIBUTE_COLUMN_NAME.updatedMunicipalities
  }

  type Option = {
    id?: number | string
    code?: number
    name: string
  }
  const findCertificationOption = (id: number | null, options: Option[]) => {
    return options.find(option => option.code === id)?.name
  }

  const findBasicMapOption = (id: number | undefined, options: Option[]) => {
    return options.find(option => option.id === id)?.name
  }

  const postBasicMapCertification = async (inputData: any, data_id: any, setLoading: (loading: boolean) => void, certification_id: number) => {
    const certification = inputData.basic_map;
    const basicMapPostData = {
      ...certification,
      id: certification_id,
      data_id: data_id
    };

    setLoading(true);
    return await postDataBasicMapCertification(basicMapPostData).then(result => {
      return result.data;
    }).finally(() => {
      setLoading(false);
    });
  }

  interface Block {
    area_id: number;
    code: string;
    id: number;
    name: string;
  }

  //基本情報タブ登録処理のリクエスト成形メソッド
  const pushBasicMapPostData = (obj: any, inputData: any, blocks: Block[], certification_id: number) => {
    const {data_tab_statuses, block_id, ...obj1} = obj;
    const basicMapCities = {...inputData.basic_map_cities};
    const submissionStatus = {id: certification_id, status: inputData.submission_status};
    return {
      ...obj,
      block_id: basicMapBlockId(blocks, obj.area_id),
      basic_map_cities: basicMapCities,
      submission_status: submissionStatus
    }
  }

  const isEmptyObject = <T extends object>(obj: T): boolean => {
    return obj && Object.keys(obj).length === 0;
  };

  //FormデータをPostDataの型に変換
  const convertBasicMapCityData = (original: BasicMapCity, isType: Config.Data): PostDataBasicMapCity => {
    const frag = () => {
      const bool = isType == Config.Data.AttributeNew || isType == Config.Data.AttributeCopy;
      return bool ? 1 : Number(original.initial_registration_flag);
    }
    return {
      id: Number(original.id) > 0 ? Number(original.id) : undefined,
      initial_registration_flag: frag(),
      other_area: original.other_area,
      plain_area: original.plain_area,
      urban_area: original.urban_area,
      remarks: original.remarks,
      status: original.status ? String(original.status) : null,
      update_target_city_code: original.update_target_city_code,
    };
  }

  //Formデータを変換して返す
  const basicMapCitiesPostData = (inputCityData: BasicMapCity[], isType: Config.Data): PostDataBasicMapCity[] => {
    return inputCityData?.map(city => convertBasicMapCityData(city, isType));
  }

  type SetErrorFn = (name: string, error: ErrorOption, options?: ({ shouldFocus: boolean } | undefined)) => void;
  const setBasicMapCityErrors = (setError: SetErrorFn, errors: any) => {
    let errorMessage = "";
    for (const key in errors) {
      for (const key2 in errors[key]) {
        errorMessage += "\n" + Common.getNestedValue(errors[key][key2]);
      }
      setError(`basic_map_cities[${key}]`, {
        type: "server",
        message: errorMessage,
      });
    }
  }


  const setBasicMapCertificationErrors = (setError: SetErrorFn, errors: any) => {
    for (const key in errors) {
      setError(key, {
        type: "server",
        message: Common.getNestedValue(errors[key]),
      });
    }
  }

  // テキストエリアサイズ調整
  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  const basicMapBlockId = (blocks: Block[], selectedArea: number | string) => {
    return blocks.find(block => block.area_id == Number(selectedArea) && block.code == Config.BASIC_MAP_BLOCK_CODE)?.id;
  }

  return {
    isUpdatedMunicipalities,
    findCertificationOption,
    findBasicMapOption,
    postBasicMapCertification,
    isEmptyObject,
    pushBasicMapPostData,
    basicMapCitiesPostData,
    setBasicMapCityErrors,
    adjustTextareaHeight,
    basicMapBlockId,
    setBasicMapCertificationErrors
  }
}