import CustomSelect from "@/components/CustomSelect";
import { importOptions, MESSAGE_NO_E39 } from "@/config";
import { Project, ProjectCategory, } from "@/stores/project";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { useLoadingStore } from "@/stores/loading";
import { getProjects } from "@/api/projects";
import { axios } from "@/lib/axios";
import { exportCsv } from "@/api/data";
import * as Common from "@/utils/common";
import {SearchRows} from "@/components/ProgressList/SearchRows";

interface ExportProps {
  setModalMessage: ( res: { type: {}, message: string } | null ) => void
}

export const DataExport = ( props: ExportProps ) => {
  const methods = useForm();
  const setLoading = useLoadingStore(state => state.setLoading);

  const [projects, setProjects] = useState<Project[]>()
  const [selectedProject, setSelectedProject] = useState<Project | null>();
  const [selectedCategory, setSelectedCategory] = useState<ProjectCategory | null>();
  const [importType, setImportType] = useState<string | null>(null);
  const [error, setError] = useState<any | null>(null);

  const [areaName, setAreaName] = useState<string>("エリア");
  const [blockName, setBlockName] = useState<string>("ブロック");
  const [areas, setAreas] = useState<any[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[] | undefined>();

  const handleProject = ( e: any ) => {
    const projectId = parseInt(e.target.value);
    const project = projects?.find(p => p.id === projectId);
    setSelectedProject(project || undefined);
    setSelectedCategory(undefined)
    methods.reset({
      area_id: '',
      block_id: '',
      attributes: [],
    });
  }
  const handleCategory = ( e: any ) => {
    const categoryId = parseInt(e.target.value);
    const category = selectedProject?.project_categories.find(c => c.id === categoryId);
    setSelectedCategory(category || undefined);
    methods.reset({
      area_id: '',
      block_id: '',
      attributes: [],
    });
  }
  const handleSelectImportType = ( e: any ) => {
    setImportType(e.target.value);
  }
  const onClickClear = () => {
    setSelectedProject(null)
    setSelectedCategory(null)
    setImportType(null)
    setAttributes(undefined)
    methods.reset({
      area_id: '',
      block_id: '',
      attributes: [],
    });
  }

  const onClickExport = async ( e: any ) => {
    let messages: any = {}
    if (!importType) {
      messages["importType"] = "必須項目です。";
    }
    if (!selectedProject) {
      messages["selectedProject"] = "必須項目です。";
    }
    if (!selectedCategory) {
      messages["selectedCategory"] = "必須項目です。";
    }
    if (!importType || !selectedProject || !selectedCategory) {
      e.preventDefault();
      setError(messages);
      return;
    }
    setLoading(true);

    const data = methods.getValues()
    const attributesParams = {
      area_id: data.area_id,
      block_id: data.block_id,
      attributes: Common.inputAttributeToPostParam(attributes ?? [], data),
    }
    const params = importType === "3" ? {
      import_type: importType,
      category_id: selectedCategory.id,
      attributes_params: attributesParams
    } : {
      import_type: importType,
      category_id: selectedCategory.id,
      attributes_params: undefined
    }

    await exportCsv(params)
      .then(( result ) => {
        //ファイルダウンロード処理
        const contentType = result.headers["content-type"];
        if (contentType === "text/csv; charset=UTF-8" && result.status === 200) {
          const blob = new Blob([result.data], { type: contentType });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = exportCsvFileName(importType);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(downloadUrl);
        }
      }).catch(( error ) => {
        if (!error.response) {
          props.setModalMessage({ type: { server: true }, message: MESSAGE_NO_E39 })
        }
      }).finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    setLoading(true);
    getProjects().then(( result ) => {
      setProjects(result.data.projects)
    }).catch(error => {
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (importType === "3" && selectedCategory) {
      setLoading(true);
      axios.get('/api/v1/attributes/searchInputs', {
        params: {
          project_id: selectedProject?.id,
          project_category_id: selectedCategory?.id,
        }
      }).then(result => {
        setAreaName(result.data.project.area_display_name);
        setBlockName(result.data.project.block_display_name);
        setAreas(result.data.areas);
        setBlocks(result.data.blocks);
        setAttributes(result.data.attributes);
      }).catch(error => {
        props.setModalMessage({ type: { server: true }, message: MESSAGE_NO_E39 })
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [selectedCategory, importType])

  return (
    <div>
      <div className="precaution">※は必須です</div>
      <form>
        <div className="admin-tbl">
          <table className="uk-width-1-1">
            <tr>
              <td>
                エクスポート対象<span className="error">※</span>
                {error?.importType && <span className="precaution">{error.importType}</span>}
              </td>
              <td>
                <div className="width-40p uk-flex">
                  <CustomSelect
                    name="exportOption"
                    placeholder="出力対象を選択"
                    options={importOptions}
                    value={importType ? importType : null}
                    required
                    onChange={handleSelectImportType}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                プロジェクト<span className="error">※</span>
                {error?.selectedProject && <span className="precaution">{error.selectedProject}</span>}
              </td>
              <td>
                <div className="width-40p uk-flex">
                  <select
                    name="category"
                    className={selectedProject ? '' : 'placeholder'}
                    value={selectedProject ? selectedProject.id : ""}
                    onChange={e => handleProject(e)}
                  >
                    <option value="" className="uk-placeholder">プロジェクトを選択</option>
                    {projects?.map(( project, index ) => (
                      <option key={index} value={project.id}>{project.name}</option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                カテゴリ<span className="error">※</span>
                {error?.selectedCategory && <span className="precaution">{error.selectedCategory}</span>}
              </td>
              <td>
                <div className="width-40p uk-flex">
                  <select
                    name="category"
                    className={selectedCategory ? '' : 'placeholder'}
                    value={selectedCategory ? selectedCategory.id : ""}
                    onChange={e => handleCategory(e)}
                  >
                    <option value="" className="uk-placeholder">カテゴリを選択</option>
                    {selectedProject?.project_categories?.map(( category, index ) => (
                      <option key={index} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </form>
      {importType === "3" && attributes && selectedCategory &&
          <FormProvider {...methods}>
              <div className="uk-margin-top">
                  <SearchContainer
                      attributes={attributes}
                      areas={areas}
                      blocks={blocks}
                      areaName={areaName}
                      blockName={blockName}
                  />
              </div>
          </FormProvider>
      }
      <div className="uk-flex uk-flex-center uk-margin-top">
        <button className="uk-button--m uk-button-cancel" onClick={onClickClear}>クリア</button>
        <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickExport}>エクスポート</button>
      </div>
    </div>
  )
}

interface SearchProps {
  attributes: Array<any>,
  areas: Array<any>,
  blocks: Array<any>,
  areaName: string,
  blockName: string
}

const SearchContainer = ( props: SearchProps ) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useFormContext()
  const selectedArea = useWatch({
    control,
    name: 'area_id',
    defaultValue: '',
  });
  const selectedBlock = useWatch({
    control,
    name: 'block_id',
    defaultValue: '',
  });

  const filteredBlocks = useMemo(() => {
    if (selectedArea) return props.blocks.filter(( block: any ) => block.area_id === parseInt(selectedArea));
    else return [];
  }, [selectedArea]);

  useEffect(() => {
    if (isDirty) {
      setValue('block_id', '');
    }
  }, [selectedArea]);

  return (
    <table className="condition-tbl">
      <tbody>
      <tr className="header">
        <td colSpan={4}>{props.areaName}</td>
      </tr>
      <tr>
        <td colSpan={4}>
          <div className="uk-flex">
            <div className="uk-inline">
              <select className={`${selectedArea ? '' : 'placeholder'} ${errors.area_id ? "error-form" : ""}`}
                      defaultValue={""} {...register("area_id", { required: '必須項目です' })}>
                <option value="" disabled>エリア選択</option>
                {props.areas.map(( o: any ) => {
                  return <option value={o.id}>{o.code + ': ' + o.name}</option>
                })}
              </select>
              {errors.area_id && <div className="error">{`${errors.area_id.message}`}</div>}
            </div>
            <div className="uk-inline uk-margin-left">
              <select className={`${selectedBlock ? '' : 'placeholder'} ${errors.block_id ? "error-form" : ""}`}
                      defaultValue={""} {...register("block_id", { required: '必須項目です' })}>
                <option value="" disabled>ブロック選択</option>
                {filteredBlocks.map(( o: any ) => {
                  return <option value={o.id}>{o.code + ': ' + o.name}</option>
                })}
              </select>
              {errors.block_id && <div className="error">{`${errors.block_id.message}`}</div>}
            </div>
          </div>
        </td>
      </tr>
      <SearchRows attributes={props.attributes}/>
      </tbody>
    </table>
  )
}

const exportCsvFileName = ( importType: string ) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  let typeName
  switch (importType) {
    case "1":
      typeName = "attributes"
      break
    case "2":
      typeName = "attribute_options"
      break
    case "3":
      typeName = "data_attribute_values"
      break
    default:
      typeName = ""
  }

  return `${typeName}_${year}${month}${day}_${hours}${minutes}${seconds}`;
}