import { axios } from "@/lib/axios";

export const importCsv = (
  form_data: any,
  import_type: string
): Promise<any> => {
  return axios.post("/api/v1/import/importCsv", form_data, {
    params: {
      import_type,
    },
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const exportCsv = (params: {
  import_type: string;
  category_id: number;
  attributes_params: any | undefined;
}) => {
  return axios.get("/api/v1/export/exportCsv", {
    params,
  });
};

export const importPostalCodeCsv = (
  form_data: any,
  import_type: string
): Promise<any> => {
  return axios.post("/api/v1/postalcode/importCsv", form_data, {
    params: {
      import_type,
    },
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
