import React, {useEffect} from "react";
import * as Attributes from "@/components/Form/Attributes";
import {useParams} from "react-router-dom";
import {useLoadingStore} from "@/stores/loading";
import {basicMapStore} from "@/stores/basicMap";
import {useBasicMap} from "@/utils/useBasicMap";
import * as Config from "@/config";

export const DetailBasicMapStatus = () => {
  const {fetchCertification, certification, fetchSubmissionStatus, submissionStatus, reset} = basicMapStore();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const {findCertificationOption} = useBasicMap();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    fetchSubmissionStatus();
    fetchCertification(Number(params.dataId));
    setLoading(false);
    return () => {
      reset();
    }
  }, [])

  return (
    <div className="info-tbl">
      <table className="uk-width-1-2">
        <tbody>
        <tr>
          <td className="label">ステータス</td>
          <td className="content">
            {findCertificationOption(certification?.submission_status, submissionStatus)}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}
export const EditBasicMapStatus = (props: { isType: Config.Data }) => {
  const store = basicMapStore();
  const {isEmptyObject} = useBasicMap();
  const params = useParams();
  const setLoading = useLoadingStore((state) => state.setLoading);
  const displayCheckBox = isEmptyObject(store.submissionStatus) || (isEmptyObject(store.certification) && props.isType !== Config.Data.AttributeNew);
  const defaultValue = props.isType !== Config.Data.AttributeCopy ? store?.certification?.submission_status : null;

  useEffect(() => {
    setLoading(true);
    store.fetchSubmissionStatus();
    store.fetchCertification(params.dataId ? Number(params.dataId) : undefined);
    setLoading(false);
    return () => {
      store.reset();
    }
  }, [])

  return (
    <div className="info-tbl">
      <table className="uk-width-1-2">
        <tbody>
        <tr>
          <td className="label">ステータス</td>
          <td className="content">
            {displayCheckBox ? <></> :
              <Attributes.BasicMapStatus name="submission_status" options={store?.submissionStatus}
                                         props={{defaultValue: defaultValue}}/>
            }
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}