import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import SubHeader from '../../components/SubHeader';
import {useProjectStore} from '@/stores/project'
import {axios} from '@/lib/axios';
import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import {useLoadingStore} from '@/stores/loading';
import * as Config from '@/config'
import * as Common from '@/utils/common'
import qs from 'qs';
import {User, useUserStore} from '@/stores/user'
import ConfirmModal from '@/components/ConfirmModal'
import {useProgressStore} from '@/stores/progress'
import 'react-tooltip/dist/react-tooltip.css'
import {useMessageModalStore} from '@/stores/message'
import {basicMapStore} from "@/stores/basicMap";
import {SearchContainer} from "@/components/ProgressList/SearchContainer";
import {SearchResultsContainer} from "@/components/ProgressList/SearchResultsContainer";
import {ServiceStartInfoChangeListButtons} from "@/components/ProgressList/results/ServiceStartInfo";
import {PROJECT_CATEGORY_ID_SERVICE_START_INFO} from "@/config";

export interface SearchResults {
  attributes: Array<any>,
  datas: Array<any>,
  tabs: Array<any>,
  dataTabStatusCounts: Array<any>,
  paging: any,
  faq_statuses: Array<any> | null,
  data_faq_status_counts: Array<any> | null,
  decisionUsers: Array<any>,
  dataApplies: Array<any>,
  basic_map_statuses: Array<any>,
}

const defaultDecision = {
  decision_user: '',
  un_applied: true,
  un_approved: true,
  approved: true,
  has_comment: true,
  no_comment: true
};

const defaultBasicMapStatuses = {
  updated_city_status: '',
  submission_status: '',
  certification_status: ''
}

export default function ProgressList() {
  const [isSearchVisible, setIsSearchVisible] = useState(true);
  const navigate = useNavigate();
  const methods = useForm();
  const {
    control,
    reset,
    getValues,
    setValue,
    register,
    formState: {errors, isDirty},
  } = methods;
  const {pathname, search} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = qs.parse(search.substring(1), {arrayLimit: 0});

  const user = useUserStore(state => state.user);
  const setLoading = useLoadingStore(state => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const [selectedProject, selectedProjectCategory] = useProjectStore(state => [
    state.project,
    state.projectCategory,
  ]);
  const [prevSelectedProjectCategory, setPrevSelectedProjectCategory] = useState(selectedProjectCategory);
  // 名前がかぶる
  const [progressQueryParams, setProgressQueryParams] = useProgressStore(state => [
    state.queryParams,
    state.setQueryParams,
  ]);
  const {fetchUpdatedCityStatus, fetchSubmissionStatus, fetchCertificationStatus} = basicMapStore();
  const isBasicMap = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_BASIC_MAP;
  const isServiceStartInfo = selectedProjectCategory?.id === Config.PROJECT_CATEGORY_ID_SERVICE_START_INFO;

  const pageSizeOptions = [
    {
      value: '25',
      label: '25件',
    },
    {
      value: '50',
      label: '50件',
    },
    {
      value: '100',
      label: '100件',
    },
    {
      value: '200',
      label: '200件',
    },
  ]

  const breadCrumbs = [
    {title: '進捗一覧'}
  ];
  // 検索項目
  const [areaName, setAreaName] = useState<string>("エリア");
  const [blockName, setBlockName] = useState<string>("ブロック");
  const [areas, setAreas] = useState<any[]>([]);
  const [blocks, setBlocks] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[] | undefined>([]);
  const [decisionUsers, setDecisionUsers] = useState<any[]>([])
  const [resetForm, setResetForm] = useState<boolean>(false);
  // 検索結果
  const [searchResults, setSearchResults] = useState<SearchResults | undefined>(undefined);
  const [searchQueryParam, setSearchQueryParam] = useState<any>(undefined);
  const [modalConfirm, setModalConfirm] = useState<{ callback: () => void } | undefined>(undefined);
  // 検索結果の入力項目
  const [checkIds, setCheckIds] = useState(new Set<number>());
  const [selectedTabStatusesId, setSelectedTabStatusesId] = useState<number | undefined>(undefined);
  const [selectedTabId, setSelectedTabId] = useState<number | undefined>(undefined); // (query.tab_id ? parseInt(query.tab_id as string) : undefined);
  const [selectedPageSize, setSelectedPageSize] = useState<string | undefined>('100')

  const toggleSearchVisibility = () => {
    setIsSearchVisible(!isSearchVisible);
  };
 
  // TODO: 共通化？
  const canCreate = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
        return true;
      case Config.PERMISSION_ROLL_ADMIN:
      case Config.PERMISSION_ROLL_USER:
        return user.user_restrictions.find((user_restriction) => {
          return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PROGRESS_NEW
            && user_restriction.category_restriction_value === 1 && user_restriction.project_category_id === selectedProjectCategory?.id
        }) !== undefined;
      default:
        return false;
    }
  }
  const canDelete = (user?: User) => {
    switch (user?.roll) {
      case Config.PERMISSION_ROLL_SYSADMIN:
        return true;
      case Config.PERMISSION_ROLL_ADMIN:
      case Config.PERMISSION_ROLL_USER:
        return user.user_restrictions.find((user_restriction) => {
          return user_restriction.category_restriction_code === Config.RESTRICTION_HISTORY_PROGRESS_DEL
            && user_restriction.category_restriction_value === 1 && user_restriction.project_category_id === selectedProjectCategory?.id
        }) !== undefined;
      default:
        return false;
    }
  }

  function getSearchInputs() {
    setResetForm(false);
    setSearchQueryParam(undefined);
    setSearchResults(undefined);
    setLoading(true);
    axios.get('/api/v1/attributes/searchInputs', {
      params: {
        project_id: selectedProject?.id,
        project_category_id: selectedProjectCategory?.id,
      }
    }).then(result => {
      setAreaName(result.data.project.area_display_name);
      setBlockName(result.data.project.block_display_name);
      setAreas(result.data.areas);
      const filteredBlocks = Common.filteredBlocks(result.data.blocks, selectedProjectCategory);
      setBlocks(filteredBlocks);
      setAttributes(result.data.attributes);
      setDecisionUsers(result.data.decision_users);

      if (Object.keys(query).length > 0 && query.project_category_id == selectedProjectCategory?.id) {
        // console.log("query")
        // console.log(query)
        // if (query.attributes) query.attributes = Common.decodeAttributeParams(result.data.attributes, query);
        setSearchQueryParam(query);
      } else if (progressQueryParams && progressQueryParams.project_category_id == selectedProjectCategory?.id) {
        // console.log("progressQueryParams")
        // console.log(progressQueryParams)
        setSearchQueryParam(progressQueryParams);
      } else {
        setResetForm(true);
      }
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function doSearch(params: any, isSetForm = false) {
    // console.log(params.attributes)
    setLoading(true);
    axios.get('/api/v1/datas/search', {
      params: params
    }).then(result => {
      // console.log(result.data)
      // console.log(result)
      // config.urlにクエリが含まれていないため変換
      setSearchParams(qs.stringify(params));
      setProgressQueryParams(params);
      if (isSetForm) {
        const attributeInputs = params.attributes ? Common.decodeAttributeParams(attributes ?? [], params) : Common.attributeDefaultValues(attributes ?? []);
        reset({
          area_id: params.area_id,
          block_id: params.block_id,
          attributes: attributeInputs,
          approval_status: params.approval_status,
          basic_map_statuses: params.basic_map_statuses
        });
      }
      setSelectedTabStatusesId(params.tab_statuses_id);
      setSelectedTabId(params.tab_id);
      setSelectedPageSize(params.page_size);
      setCheckIds(new Set());
      setSearchResults(result.data as SearchResults);
    }).catch(error => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  function deleteIds() {
    setModalConfirm({
      callback: () => {
        setLoading(true);
        axios.post('/api/v1/datas/deleteByIds', {
          ids: Array.from(checkIds),
        }).then(result => {
          doSearch(searchQueryParam ?? defaultSearchParam());
        }).catch(error => {
          setMessage(Config.MESSAGE_NO_E39);
          setLoading(false);
        });
      }
    });
  }

  function clearForm() {
    reset({
      area_id: '',
      block_id: '',
      attributes: Common.attributeDefaultValues(attributes ?? []),
      approval_status: defaultDecision,
      basic_map_statuses: defaultBasicMapStatuses
    });
    navigate(pathname);
  }

  useEffect(() => {
    if (selectedProjectCategory) {
      if (isBasicMap) {
        fetchUpdatedCityStatus();
        fetchSubmissionStatus();
        fetchCertificationStatus();
      }
      getSearchInputs();
    }
    return () => {
    };
  }, [selectedProjectCategory]);

  // attributesの設定後
  useEffect(() => {
    if (attributes && searchQueryParam) {
      doSearch(searchQueryParam, true);
    }
    return () => {
    };
  }, [attributes, searchQueryParam]);

  useEffect(() => {
    if (attributes && resetForm) {
      clearForm();
      doSearch(defaultSearchParam());
    }
    return () => {
    };
  }, [attributes, resetForm]);

  function defaultSearchParam() {
    const data = getValues();
    return {
      project_category_id: selectedProjectCategory?.id,
      area_id: data.area_id,
      block_id: data.block_id,
      attributes: Common.inputAttributeToPostParam(attributes ?? [], data)[0],
      tab_statuses_id: null,
      tab_id: null,
      page: 1,
      page_size: selectedPageSize,
      approval_status: data.approval_status,
      basic_map_statuses: data.basic_map_statuses
    }
  }


  const onClickNew = () => {
    navigate(Common.attributesNewUrl(selectedProjectCategory?.id));
  }

  const onClickCheck = (id: number) => {
    if (checkIds.has(id)) {
      checkIds.delete(id);
      setCheckIds(new Set(checkIds));
    } else {
      checkIds.add(id);
      setCheckIds(new Set(checkIds));
    }
  }

  const onClickCheckAll = () => {
    if (checkIds.size === searchResults?.datas.length) {
      setCheckIds(new Set<number>());
    } else {
      setCheckIds(new Set<number>(searchResults?.datas.map((data: any) => data.id)));
    }
  }

  const onClickDeleteIds = () => {
    deleteIds();
  }

  const onClickCopy = () => {
    const copy = searchResults?.datas.find((data: any) => data.id === Array.from(checkIds)[0]);
    navigate(Common.attributesCopyUrl(selectedProjectCategory?.id, copy.id));
  }

  const onSelectedTabStatusesId = (tabStatusesId: number) => {
    doSearch({
      ...progressQueryParams,
      tab_statuses_id: tabStatusesId,
      tab_id: null,
      page: 1,
    });
  }

  const onSelectedTabId = (tabId: number) => {
    doSearch({
      ...progressQueryParams,
      tab_statuses_id: null,
      tab_id: tabId,
      page: 1,
    });
  }

  const onSelectedFaqStatuses = (statusId: number) => {
    doSearch({
      ...progressQueryParams,
      faq_statuses_id: statusId,
      page: 1,
    });
  }

  const onSelectedFaq = () => {
    doSearch({
      ...progressQueryParams,
      faq_statuses_id: null,
      page: 1,
    });
  }

  const onSelectedPageSize = (pageSize: string) => {
    setSelectedPageSize(pageSize);
    if (progressQueryParams) {
      doSearch({
        ...progressQueryParams,
        page_size: pageSize,
        page: 1,
      });
    }
  }

  const onPageChange = (page: number) => {
    doSearch({
      ...progressQueryParams,
      page: page
    });
  }

  const onClear = () => {
    clearForm();
  }

  const onSearch = () => {
    const data = getValues();
    doSearch({
      project_category_id: selectedProjectCategory?.id,
      area_id: data.area_id,
      block_id: data.block_id,
      attributes: Common.inputAttributeToPostParam(attributes ?? [], data)[0],
      basic_map_statuses: data.basic_map_statuses,
      tab_statuses_id: null,
      tab_id: null,
      page: 1,
      page_size: selectedPageSize,
      approval_status: data.approval_status
    });
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs}/>
      </div>
      <div className="container container-progress">
        <FormProvider {...methods}>
          {attributes && isSearchVisible && <SearchContainer
              attributes={attributes}
              areas={areas}
              blocks={blocks}
              areaName={areaName}
              blockName={blockName}
              decisionUsers={decisionUsers}
              onSearch={onSearch}
              onClear={onClear}
              toggleSearchVisibility={toggleSearchVisibility}
          />}
        </FormProvider>
        {!isSearchVisible &&
          <>
            <hr/>
            <div className="uk-flex uk-flex-center">
              <button type="button" className="uk-button--m uk-button-cancel uk-margin-left" onClick={toggleSearchVisibility}>検索表示</button>
            </div>
          </>
        }
        <div className="uk-flex-between uk-flex uk-flex-middle mt-5">
          <div className="uk-inline">
            <select className="table-length" defaultValue={"100"}
                    onChange={(e) => onSelectedPageSize(e.target.value)}>
              <option value="" disabled>表示件数</option>
              {pageSizeOptions.map((o: any) => {
                return <option key={`page-size-options-${o.value}`} value={o.value}>{o.label}</option>
              })}
            </select>
            {isServiceStartInfo && <ServiceStartInfoChangeListButtons />}
          </div>
          <div>
            {canDelete(user) && <button className="uk-button--m uk-button-cancel" onClick={onClickDeleteIds}
                                        disabled={checkIds.size === 0}>一括削除</button>}
            {canCreate(user) &&
                <>
                    <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickCopy}
                            disabled={checkIds.size !== 1}>複製
                    </button>
                    <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickNew}>新規作成
                    </button>
                </>
            }
          </div>
        </div>

        {searchResults &&
            <SearchResultsContainer
                searchResults={searchResults}
                areaName={areaName}
                blockName={blockName}
                checkIds={checkIds}
                onClickCheck={onClickCheck}
                onClickCheckAll={onClickCheckAll}
                onPageChange={onPageChange}
                selectedTabStatusesId={selectedTabStatusesId}
                onSelectedTabStatusesId={onSelectedTabStatusesId}
                selectedTabId={selectedTabId}
                onSelectedTabId={onSelectedTabId}
                selectedProjectCategory={selectedProjectCategory}
                onSelectedFaq={onSelectedFaq}
                onSelectedFaqStatus={onSelectedFaqStatuses}
            />}
      </div>
      {/*<button onClick={()=> {console.log(getValues())}}>debug</button>*/}

      <ConfirmModal
        text={Config.MESSAGE_NO_E05}
        confirmButtonText="OK"
        isShow={modalConfirm !== undefined}
        onConfirm={() => {
          if (modalConfirm) modalConfirm.callback();
          setModalConfirm(undefined);
        }}
        onCancel={() => {
          setModalConfirm(undefined);
        }}
      />
    </div>
  );
}

