import create from 'zustand';
import {Attribute} from "@/components/ProgressList/types";
import * as Config from "@/config";

type ProgressStore = {
  queryParams: any | undefined;
  setQueryParams: (any: any | undefined) => void;
  tabs: any[];
  setTabs: (tabs: any[]) => void;
  listAttributes: Attribute[];
  setListAttributes: (listAttributes: Attribute[]) => void;
  serviceStartInfoType: Config.ServiceStartInformationList;
  setServiceStartInfoType: (serviceStartInfoType: Config.ServiceStartInformationList) => void;
  serviceInfoSourceAttr: Attribute | undefined;
  setServiceInfoSourceAttr: (serviceInfoSourceAttr: Attribute | undefined) => void;
};

// 検索条件の保持に使用
export const useProgressStore = create<ProgressStore>((set) => ({
  queryParams: undefined,
  setQueryParams: (queryParams) =>
    set((state) => ({
      queryParams: queryParams,
    })),
  tabs: [],
  setTabs: (tabs) =>
    set((state) => ({
      tabs: tabs,
    })),
  listAttributes: [],
  setListAttributes: (listAttributes) =>
    set((state) => ({
      listAttributes: listAttributes,
    })),
  serviceStartInfoType: Config.ServiceStartInformationList.progress,
  setServiceStartInfoType: (serviceStartInfoType) =>
    set((state) => ({
      serviceStartInfoType: serviceStartInfoType,
    })),
  serviceInfoSourceAttr: {} as Attribute,
  setServiceInfoSourceAttr: (serviceInfoSourceAttr) =>
    set((state) => ({
      serviceInfoSourceAttr: serviceInfoSourceAttr,
    })),
}));
