import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SubHeader from '../../../components/SubHeader';
import { SubmitHandler, useForm } from "react-hook-form";
import { useLoadingStore } from "@/stores/loading";
import { TextBox } from "@/components/Form/TextBox";
import ConfirmModal from "@/components/ConfirmModal";
import { createUser, getDuplicateData, getLoginUserInfo, getUserData, updateUser } from "@/api/users";
import { TextArea } from "@/components/Form/TextArea";
import { RadioButton } from "@/components/Form/RadioButton";
import { User, useUserStore } from "@/stores/user";
import * as Config from "@/config";
import * as Common from "@/utils/common";
import {UserInfo} from "@/containers/admin/Users/types";


export default function UserEdit() {
  const location = useLocation();
  const isEdit = location.pathname.includes('/edit');
  const isDuplicate = location.pathname.includes('/duplicate');
  const isNew = location.pathname.includes('/new');
  const navigate = useNavigate();
  const userId = useParams().id;
  const setLoading = useLoadingStore(state => state.setLoading);
  const [user, setUser] = useUserStore(state => [state.user, state.setUser]);
  const canUser = Common.canUserManagement(user)

  const title = () => {
    if (isNew) {
      return "新規作成"
    } else if (isEdit) {
      return "編集"
    } else {
      return "複製"
    }
  }

  const breadCrumbs = [
    { title: '管理画面', path: '/admin' },
    { title: 'ユーザ一覧', path: '/admin/users' },
    { title: title() }
  ];

  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
  const [pfPasswordHide, setPfPasswordHide] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<UserInfo>(
    {
      mode: 'onSubmit',
      reValidateMode: 'onSubmit',
      criteriaMode: 'all',
      defaultValues: userInfo,
      values: userInfo,
    }
  );
  const hasErrors = Object.keys(errors).length > 0;

  const onClickCancel = () => {
    setIsShowBackModal(true);
  }

  const onClickSave = () => {
    handleSubmit(onSubmit)();
  }

  const onSubmit: SubmitHandler<UserInfo> = ( data ) => {
    setEmailErrorMessage(null);
    setLoading(true)
    const response = isEdit ? updateUser(data) : createUser(data)
    response.then(result => {
      getLoginUserInfo().then(result => {
        setUser(result.data)
      }).finally(() => {
        navigate(-1)
        setLoading(false);
      })
    }).catch(error => {
      const res = error.response;
      if (res) {
        if (res.data['email_address']) {
          setEmailErrorMessage(res.data['email_address']);
        } else {
          setErrorMessage(Config.MESSAGE_NO_E37);
        }
      } else {
        setErrorMessage(Config.MESSAGE_NO_E37);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    if (canUser < Config.RESTRICTION_HISTORY_USER_OK.value) {
      navigate('/admin');
    }
    setLoading(true)
    let response;
    if (isEdit) {
      response = getUserData(userId);
    } else if (isDuplicate) {
      response = getDuplicateData(userId);
    } else {
      response = getUserData();
    }
    response.then(( result ) => {
      setUserInfo(result.data)
    }).catch(error => {
      setErrorMessage(Config.MESSAGE_NO_E39)
    }).finally(() => {
      setLoading(false);
    });
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage])

  const watchedProjects = watch('projects', []);
  const watchedRoll = watch('roll');

  const isAtLeastOneChecked = () => {
    return watchedProjects.some(project => project.selected) || Config.MESSAGE_NO_E08;
  }

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="ユーザ管理" showPrompt/>
        {isEdit &&
            <div className="uk-text-right uk-margin-small-bottom">
                最終更新ユーザ：{userInfo?.last_updated_by} 最終更新:{userInfo?.last_updated_at}
            </div>
        }
        {hasErrors && <div className="error-box">入力エラーがあります</div>}
        <div className="uk-flex-between uk-flex uk-flex-middle">
          <div className="precaution">※は必須です</div>
          <div>
            <button className="uk-button--m uk-button-cancel" onClick={onClickCancel}>キャンセル</button>
            <button className="uk-button--m uk-button-refer uk-margin-left" onClick={onClickSave}>保存</button>
          </div>
        </div>
      </div>
      <div className="container">
        <form>
          <div className="admin-tbl uk-margin-small-top">
            <table className="uk-width-1-1">
              <tr>
                <td>メールアドレス<span className="error">※</span></td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="email_address"
                      register={register}
                      rules={{
                        required: Config.MESSAGE_NO_E08,
                        maxLength: { value: 254, message: Config.MESSAGE_NO_E35(254) },
                      }}
                      errors={errors}
                      placeholder={"メールアドレスを入力してください"}
                    />
                  </div>
                  {errors.email_address && <p className="precaution">{errors.email_address.message}</p>}
                  {emailErrorMessage && <p className="precaution">{emailErrorMessage}</p>}
                </td>
              </tr>
              <tr>
                <td>姓名<span className="error">※</span></td>
                <td>
                  <div className="uk-flex gap-10 width-40p">
                    <div className="uk-flex-1">
                      <TextBox
                        name="last_name"
                        register={register}
                        rules={{
                          required: Config.MESSAGE_NO_E08,
                          maxLength: { value: 30, message: Config.MESSAGE_NO_E35(30) },
                        }}
                        errors={errors}
                        placeholder={"姓を入力してください"}
                      />
                    </div>
                    <div className="uk-flex-1">
                      <TextBox
                        name="first_name"
                        register={register}
                        rules={{ maxLength: { value: 30, message: Config.MESSAGE_NO_E35(30) }, }}
                        errors={errors}
                        placeholder={"名を入力してください"}
                      />
                    </div>
                  </div>
                  {errors.last_name && <p className="precaution">{errors.last_name.message}</p>}
                  {errors.first_name && <p className="precaution">{errors.first_name.message}</p>}
                </td>
              </tr>
              <tr>
                <td>プロジェクト<span className="error">※</span></td>
                <td>
                  <div className="uk-grid-small uk-grid">
                    {watchedProjects.map(( project, index ) => (
                      <label key={index}>
                        <div>
                          <input
                            type="checkbox"
                            {...register(
                              `projects.${index}.selected`,
                              { validate: isAtLeastOneChecked, }
                            )}
                          />
                        </div>
                        {project.name}
                      </label>
                    ))}
                  </div>
                  {watchedProjects.find(( project, index ) => errors.projects?.[index]?.selected) &&
                      <p className="precaution">{Config.MESSAGE_NO_E08}</p>
                  }
                </td>
              </tr>
              <tr>
                <td>グループ<span className="error">※</span></td>
                <td>
                  <div className="width-40p uk-flex">
                    <select
                      {...register("selected_group")}
                    >
                      {userInfo?.groups?.map(( group, index ) => (
                        <option key={index} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <td>DRM-PF ログインID</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="drm_pf_login_id"
                      register={register}
                      rules={{ maxLength: { value: 30, message: Config.MESSAGE_NO_E35(30) }, }}
                      errors={errors}
                      placeholder={"DRM-PF　ログインIDを入力してください"}
                    />
                  </div>
                  {errors.drm_pf_login_id && <p className="precaution">{errors.drm_pf_login_id.message}</p>}
                </td>
              </tr>
              <tr>
                <td>DRM-PF パスワード</td>
                <td>
                  <div className="width-40p uk-flex uk-position-relative">
                    <TextBox
                      name="drm_pf_password"
                      register={register}
                      inputType={pfPasswordHide ? "password" : "text"}
                      rules={{
                        maxLength: { value: 16, message: Config.MESSAGE_NO_E35(16) },
                        minLength: { value: 6, message: Config.MESSAGE_NO_E67(6) },
                        pattern: { value: /^[a-zA-Z0-9_-]+$/, message: Config.MESSAGE_NO_E03 }
                      }}
                      errors={errors}
                      placeholder={"DRM-PF　パスワードを入力してください"}
                      autocomplete="new-password"
                    />
                    <button
                      id="buttonEye"
                      type="button"
                      className={
                        pfPasswordHide
                          ? "uk-form-icon uk-form-icon-flip fa fa-eye"
                          : "uk-form-icon uk-form-icon-flip fa fa-eye-slash"
                      }
                      onClick={() => setPfPasswordHide(!pfPasswordHide)}
                    ></button>
                  </div>
                  {errors.drm_pf_password && <p className="precaution">{errors.drm_pf_password.message}</p>}
                </td>
              </tr>
              <tr>
                <td>作成日</td>
                <td>
                  <div className="width-40p uk-flex">{isEdit ? userInfo?.created : "自動取得"}</div>
                  {errors.expiration_date && <p className="precaution">{errors.expiration_date.message}</p>}
                </td>
              </tr>
              <tr>
                <td>有効期限<span className="error">※</span></td>
                <td>
                  <div className="width-40p uk-flex">
                    <input
                      type="date"
                      {...register("expiration_date", {
                        required: Config.MESSAGE_NO_E08,
                      })}
                      placeholder="有効期限を入力してください"
                    />
                  </div>
                  {errors.expiration_date && <p className="precaution">{errors.expiration_date.message}</p>}
                </td>
              </tr>
              <tr>
                <td>電話番号</td>
                <td>
                  <div className="width-40p uk-flex">
                    <TextBox
                      name="phone_number"
                      register={register}
                      rules={{ maxLength: { value: 21, message: Config.MESSAGE_NO_E35(21) }, }}
                      errors={errors}
                      placeholder={"電話番号を入力してください"}
                    />
                  </div>
                  {errors.phone_number && <p className="precaution">{errors.phone_number.message}</p>}
                </td>
              </tr>
              <tr>
                <td>備考</td>
                <td>
                  <TextArea
                    name="remarks"
                    register={register}
                    rules={{
                      maxLength: { value: 200, message: Config.MESSAGE_NO_E36 },
                    }}
                    errors={errors}
                    placeholder={"備考を入力してください"}
                  />
                </td>
                {errors.remarks && <p className="precaution">{errors.remarks.message}</p>}
              </tr>
              <tr>
                <td>権限<span className="error">※</span></td>
                <td>
                  <div className="uk-grid-small uk-grid">
                    <RadioButton
                      name="roll"
                      options={Config.PERMISSION_ROLL}
                      register={register}
                      rules={{ required: Config.MESSAGE_NO_E08 }}
                      errors={errors}
                      roll={userInfo?.roll}
                      loginRoll={user?.roll}
                    />
                  </div>
                  {errors.roll && <p className="precaution">{errors.roll.message}</p>}
                </td>
              </tr>
            </table>
          </div>
          {watchedRoll === Config.PERMISSION_ROLL_ADMIN && (
            <div className="admin-tbl uk-margin-top">
              <table className="uk-width-1-1">
                <>
                  <tr>
                    <td className="width-100" colSpan={2}>管理画面 操作制限</td>
                  </tr>
                  <tr>
                    <td>ユーザマスタ<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_user"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_USER]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_USER, user)}
                        />
                      </div>
                      {errors.restriction_user && <p className="precaution">{errors.restriction_user.message}</p>}
                    </td>
                  </tr>
                  <tr>
                    <td>データインポート<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_data_import"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_DATA_IMPORT]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_DATA_IMPORT, user)}
                        />
                      </div>
                      {errors.restriction_data_import &&
                          <p className="precaution">{errors.restriction_data_import.message}</p>}
                    </td>
                  </tr>
                  <tr>
                    <td>データエクスポート<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_data_export"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_DATA_EXPORT]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_DATA_EXPORT, user)}
                        />
                      </div>
                      {errors.restriction_data_export &&
                          <p className="precaution">{errors.restriction_data_export.message}</p>}
                    </td>
                  </tr>
                  <tr>
                    <td>お知らせ管理<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_manage_information"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_MANAGE_INFORMATION]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_MANAGE_INFORMATION, user)}
                        />
                      </div>
                      {errors.restriction_manage_information &&
                          <p className="precaution">{errors.restriction_manage_information.message}</p>}
                    </td>
                  </tr>
                  <tr>
                    <td>資料管理<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_document_management"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT, user)}
                        />
                      </div>
                      {errors.restriction_document_management &&
                          <p className="precaution">{errors.restriction_document_management.message}</p>}
                    </td>
                  </tr>
                  <tr>
                    <td>資料収集管理<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_material_collection_list_management"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT, user)}
                        />
                      </div>
                      {errors.restriction_Material_Collection_List_management &&
                          <p className="precaution">{errors.restriction_Material_Collection_List_management.message}</p>}
                    </td>
                  </tr>
                  <tr>
                    <td>基盤地図管理<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name="restriction_basic_map_management"
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT, user)}
                        />
                      </div>
                      {errors.restriction_basic_map_management &&
                          <p className="precaution">{errors.restriction_basic_map_management.message}</p>}
                    </td>
                  </tr>
                </>
              </table>
            </div>
          )}
          <div className="admin-tbl uk-margin-top">
            <table className="uk-width-1-1">
              {(watchedRoll === Config.PERMISSION_ROLL_ADMIN || watchedRoll === Config.PERMISSION_ROLL_USER) && (
                <>
                  <tr>
                    <td className="width-100" colSpan={2}>顧客情報</td>
                  </tr>
                  <tr>
                    <td>顧客情報<span className="error">※</span></td>
                    <td>
                      <div className="uk-grid-small uk-grid">
                        <RadioButton
                          name={"restriction_billing_list"}
                          options={Config.RESTRICTION_VALUES[Config.RESTRICTION_BILLING_LIST]}
                          register={register}
                          rules={{ required: Config.MESSAGE_NO_E08 }}
                          errors={errors}
                          loginRestriction={loginRestriction(Config.RESTRICTION_BILLING_LIST, user)}
                        />
                      </div>
                      {errors.restriction_billing_list &&
                          <p className="precaution">{errors.restriction_billing_list?.message}</p>}
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
          {watchedProjects.map(( project, projectIndex ) => {
            return project.selected && watchedRoll !== Config.PERMISSION_ROLL_SYSADMIN && (
              <div key={projectIndex}>
                {project.categories.map(( category, categoryIndex ) => {
                  const watchedRestrictionProgressNew = watch(`projects.${projectIndex}.categories.${categoryIndex}.restriction_progress_new`)
                  return (
                    <div className="admin-tbl uk-margin-top uk-margin-bottom" key={categoryIndex}>
                      <table className="uk-width-1-1">
                        <tr>
                          <td className="width-100" colSpan={2}>{project.name} - {category.name}</td>
                        </tr>
                        <tr>
                          <td className="width-100" colSpan={2}>進捗一覧画面</td>
                        </tr>
                        <tr>
                          <td>新規作成・複製<span className="error">※</span></td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <RadioButton
                                name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_progress_new`}
                                options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_PROGRESS_NEW]}
                                register={register}
                                rules={{ required: Config.MESSAGE_NO_E08 }}
                                errors={errors}
                                loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_PROGRESS_NEW, user, category.id)}
                              />
                            </div>
                            {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_new &&
                                <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_new?.message}</p>}
                          </td>
                        </tr>
                        <tr>
                          <td>削除<span className="error">※</span></td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <RadioButton
                                name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_progress_del`}
                                options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_PROGRESS_DEL]}
                                register={register}
                                rules={{ required: Config.MESSAGE_NO_E08 }}
                                errors={errors}
                                loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_PROGRESS_DEL, user, category.id)}
                              />
                            </div>
                            {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_del &&
                                <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_progress_del?.message}</p>}
                          </td>
                        </tr>
                        {category.code === Config.CATEGORY_CODE_SALES_APPLY && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}承認画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}承認画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_approve`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_APPROVE]}
                                    register={register}
                                    rules={{ required: Config.MESSAGE_NO_E08 }}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_APPROVE, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_approve &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_approve?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="width-100" colSpan={2}>{'\u3000\u3000'}属性画面<span
                            className="error">※「新規作成・複製」権限がある場合、先頭のタブの「閲覧・編集」権限を付与すること</span></td>
                        </tr>
                        {category.tabs.map(( tab, tabIndex ) => {
                          return (
                            <tr key={tabIndex}>
                              <td>{'\u3000\u3000'}{tab.name}<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.tabs.${tabIndex}.restriction_attributes_tab`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB]}
                                    register={register}
                                    rules={{ required: Config.MESSAGE_NO_E08 }}
                                    errors={errors}
                                    setValue={setValue}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_ATTRIBUTES_TAB, user, category.id, tab.id)}
                                    watchedRestrictionProgressNew={tabIndex === 0 && watchedRestrictionProgressNew}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.tabs?.[tabIndex]?.restriction_attributes_tab &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.tabs?.[tabIndex]?.restriction_attributes_tab?.message}</p>}
                              </td>
                            </tr>
                          )
                        })}
                        {category.code === Config.CATEGORY_CODE_SALES_APPLY && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}申込エリア画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}申込エリア画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_apply_area`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_APPLY_AREA]}
                                    register={register}
                                    rules={{ required: Config.MESSAGE_NO_E08 }}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_APPLY_AREA, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_apply_area &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_apply_area?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        {(category.code === Config.CATEGORY_CODE_SALES_APPLY || category.code === Config.CATEGORY_CODE_FAQ) && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}チケット画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}チケット画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_ticket`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_TICKET]}
                                    register={register}
                                    rules={{ required: Config.MESSAGE_NO_E08 }}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_TICKET, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_ticket &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_ticket?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        {category.code === Config.CATEGORY_CODE_SALES_APPLY && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}帳票画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}帳票画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_form`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_FORM]}
                                    register={register}
                                    rules={{ required: Config.MESSAGE_NO_E08 }}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_FORM, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_form &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_form?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        {category.code === Config.CATEGORY_CODE_FAQ && (
                          <>
                            <tr>
                              <td className="width-100" colSpan={2}>{'\u3000\u3000'}公開画面</td>
                            </tr>
                            <tr>
                              <td>{'\u3000\u3000'}公開画面<span className="error">※</span></td>
                              <td>
                                <div className="uk-grid-small uk-grid">
                                  <RadioButton
                                    name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_public`}
                                    options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_PUBLIC]}
                                    register={register}
                                    rules={{ required: Config.MESSAGE_NO_E08 }}
                                    errors={errors}
                                    loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_PUBLIC, user, category.id)}
                                  />
                                </div>
                                {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_public &&
                                    <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_public?.message}</p>}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td className="width-100" colSpan={2}>{'\u3000\u3000'}ファイル画面</td>
                        </tr>
                        <tr>
                          <td>{'\u3000\u3000'}ファイル画面<span className="error">※</span></td>
                          <td>
                            <div className="uk-grid-small uk-grid">
                              <RadioButton
                                name={`projects.${projectIndex}.categories.${categoryIndex}.restriction_file`}
                                options={Config.RESTRICTION_VALUES[Config.RESTRICTION_HISTORY_FILE]}
                                register={register}
                                rules={{ required: Config.MESSAGE_NO_E08 }}
                                errors={errors}
                                loginRestriction={loginRestriction(Config.RESTRICTION_HISTORY_FILE, user, category.id)}
                              />
                            </div>
                            {errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_file &&
                                <p className="precaution">{errors.projects?.[projectIndex]?.categories?.[categoryIndex]?.restriction_file?.message}</p>}
                          </td>
                        </tr>
                      </table>
                    </div>)
                })}
              </div>
            )
          })}
        </form>
      </div>
      <ConfirmModal
        text="登録をキャンセルしますか？入力したデータは登録されません。"
        confirmButtonText="前の画面へ"
        isShow={isShowBackModal}
        onConfirm={() => {
          navigate(-1)
        }}
        onCancel={() => {
          setIsShowBackModal(false);
        }}
      />
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
              }}
          />
      }
    </div>
  );
}

const loginRestriction = ( restrictionCode: any, user?: User, categoryId?: any, tabId?: any ) => {
  if (!user) {
    return 0;
  }
  if (user.roll === Config.PERMISSION_ROLL_SYSADMIN) {
    return 2;
  }
  if (categoryId && tabId) {
    return user.user_restrictions.find(( restriction ) => {
      return restriction.project_category_id === categoryId
        && restriction.category_restriction_code === restrictionCode
        && restriction.tab_id == tabId
    })?.category_restriction_value || 0
  } else if (categoryId) {
    return user.user_restrictions.find(( restriction ) => {
      return restriction.project_category_id === categoryId
        && restriction.category_restriction_code === restrictionCode
    })?.category_restriction_value || 0
  } else {
    return user.user_restrictions.find(( restriction ) => {
      return restriction.category_restriction_code === restrictionCode
    })?.category_restriction_value || 0
  }
}