export const API_URL = process.env.REACT_APP_API_URL as string;
export const TITLE = process.env.REACT_APP_TITLE as string;
export const HEADER_ENV = process.env.REACT_APP_HEADER_ENV as string;
export const DRM_PF_MAP_URL = process.env.REACT_APP_DRM_PF_MAP_URL as string;
export const DRM_PF_FREE_MAP_URL = process.env
  .REACT_APP_DRM_PF_FREE_MAP_URL as string;
export const PF_DRM_BASE_URL = process.env.REACT_APP_PF_DRM_BASE_URL as string;
export const PF_DRM_USER_NAME = process.env
  .REACT_APP_PF_DRM_USER_NAME as string;
export const PF_DRM_PASSWORD = process.env.REACT_APP_PF_DRM_PASSWORD as string;
export const DRM_PF_MAP_ID = process.env.REACT_APP_DRM_PF_MAP_ID as string;
export const DRM_PF_MAP_PASSWORD = process.env
  .REACT_APP_DRM_PF_MAP_PASSWORD as string;
export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const SPLIT_CODE = "@@@"; // selectの区切り文字
export const SYSTEM_ID = "drm-dx";

//カテゴリid
export const PROJECT_CATEGORY_ID_HISTORY = 1;
export const PROJECT_CATEGORY_ID_BASIC_MAP = 2;
export const PROJECT_CATEGORY_ID_SERVICE_START_INFO = 6;

// カテゴリ
export const CATEGORY_CODE_HISTORY = "01";
export const CATEGORY_CODE_SALES_APPLY = "02";
export const CATEGORY_CODE_FAQ = "03";
export const CATEGORY_CODE_BILLING = "04";

// 帳票
export const REPORT_CODE_DRAFT = "01";
export const REPORT_CODE_ESTIMATE = "02";
export const REPORT_CODE_DELIVERY = "03";
export const REPORT_CODE_INVOICE = "04";

// カテゴリ権限
export const RESTRICTION_HISTORY_PROGRESS_NEW = "010100";
export const RESTRICTION_HISTORY_PROGRESS_NEW_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_PROGRESS_NEW_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_PROGRESS_DEL = "010101";
export const RESTRICTION_HISTORY_PROGRESS_DEL_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_PROGRESS_DEL_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_ATTRIBUTES_TAB = "010299";
export const RESTRICTION_HISTORY_ATTRIBUTES_TAB_HIDE = {
  label: "非表示",
  value: 0,
};
export const RESTRICTION_HISTORY_ATTRIBUTES_TAB_VIEW = {
  label: "閲覧のみ",
  value: 1,
};
export const RESTRICTION_HISTORY_ATTRIBUTES_TAB_EDIT = {
  label: "閲覧・編集",
  value: 2,
};
export const RESTRICTION_HISTORY_FILE = "010302";
export const RESTRICTION_HISTORY_FILE_HIDE = {label: "非表示", value: 0};
export const RESTRICTION_HISTORY_FILE_VIEW = {label: "閲覧のみ", value: 1};
export const RESTRICTION_HISTORY_FILE_EDIT = {
  label: "閲覧・編集（新作成・編集・削除）",
  value: 2,
};
export const RESTRICTION_HISTORY_USER = "011050";
export const RESTRICTION_HISTORY_USER_NG = {label: "権限なし", value: 0};
export const RESTRICTION_HISTORY_USER_OK = {label: "権限あり", value: 1};
export const RESTRICTION_HISTORY_DATA_IMPORT = "011151";
export const RESTRICTION_HISTORY_DATA_IMPORT_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_DATA_IMPORT_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_DATA_EXPORT = "011152";
export const RESTRICTION_HISTORY_DATA_EXPORT_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_DATA_EXPORT_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_MANAGE_INFORMATION = "011253";
export const RESTRICTION_HISTORY_MANAGE_INFORMATION_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_MANAGE_INFORMATION_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT = "011354";
export const RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT = "011455";
export const RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK = {
  label: "権限あり",
  value: 1,
};
export const RESTRICTION_HISTORY_APPROVE = "022000";
export const RESTRICTION_HISTORY_APPROVE_HIDE = {label: "非表示", value: 0};
export const RESTRICTION_HISTORY_APPROVE_VIEW = {
  label: "閲覧・承認",
  value: 1,
};
export const RESTRICTION_HISTORY_APPROVE_ADMIN = {
  label: "決裁権限管理者",
  value: 2,
};
export const RESTRICTION_HISTORY_APPLY_AREA = "022101";
export const RESTRICTION_HISTORY_APPLY_AREA_HIDE = {
  label: "非表示",
  value: 0,
};
export const RESTRICTION_HISTORY_APPLY_AREA_VIEW = {label: "閲覧", value: 1};
export const RESTRICTION_HISTORY_APPLY_AREA_EDIT = {
  label: "閲覧・編集",
  value: 2,
};
export const RESTRICTION_HISTORY_FORM = "022302";
export const RESTRICTION_HISTORY_FORM_HIDE = {label: "非表示", value: 0};
export const RESTRICTION_HISTORY_FORM_VIEW = {label: "閲覧", value: 1};
export const RESTRICTION_HISTORY_FORM_EDIT = {
  label: "ファイル保存",
  value: 2,
};

//FAQ
export const RESTRICTION_HISTORY_TICKET = "030100";
export const RESTRICTION_HISTORY_TICKET_HIDE = {label: "非表示", value: 0};
export const RESTRICTION_HISTORY_TICKET_VIEW = {label: "閲覧", value: 1};
export const RESTRICTION_HISTORY_TICKET_EDIT = {
  label: "閲覧・編集",
  value: 2,
};
export const RESTRICTION_HISTORY_PUBLIC = "030200";
export const RESTRICTION_HISTORY_PUBLIC_HIDE = {label: "非表示", value: 0};
export const RESTRICTION_HISTORY_PUBLIC_VIEW = {label: "閲覧", value: 1};
export const RESTRICTION_HISTORY_PUBLIC_EDIT = {
  label: "閲覧・編集",
  value: 2,
};

// 課金システム
export const RESTRICTION_BILLING_LIST = "040100";
export const RESTRICTION_BILLING_LIST_NG = {label: "権限なし", value: 0};
export const RESTRICTION_BILLING_LIST_OK = {label: "権限あり", value: 1};

// 供用開始 資料収集リスト
export const RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT = "051556";
export const RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_NG = {
  label: "権限なし",
  value: 0,
};
export const RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_OK = {
  label: "権限あり",
  value: 1,
};

export const RESTRICTION_VALUES = {
  [RESTRICTION_HISTORY_PROGRESS_NEW]: [
    RESTRICTION_HISTORY_PROGRESS_NEW_NG,
    RESTRICTION_HISTORY_PROGRESS_NEW_OK,
  ],
  [RESTRICTION_HISTORY_PROGRESS_DEL]: [
    RESTRICTION_HISTORY_PROGRESS_DEL_NG,
    RESTRICTION_HISTORY_PROGRESS_DEL_OK,
  ],
  [RESTRICTION_HISTORY_ATTRIBUTES_TAB]: [
    RESTRICTION_HISTORY_ATTRIBUTES_TAB_HIDE,
    RESTRICTION_HISTORY_ATTRIBUTES_TAB_VIEW,
    RESTRICTION_HISTORY_ATTRIBUTES_TAB_EDIT,
  ],
  [RESTRICTION_HISTORY_FILE]: [
    RESTRICTION_HISTORY_FILE_HIDE,
    RESTRICTION_HISTORY_FILE_VIEW,
    RESTRICTION_HISTORY_FILE_EDIT,
  ],
  [RESTRICTION_HISTORY_USER]: [
    RESTRICTION_HISTORY_USER_NG,
    RESTRICTION_HISTORY_USER_OK,
  ],
  [RESTRICTION_HISTORY_DATA_IMPORT]: [
    RESTRICTION_HISTORY_DATA_IMPORT_NG,
    RESTRICTION_HISTORY_DATA_IMPORT_OK,
  ],
  [RESTRICTION_HISTORY_DATA_EXPORT]: [
    RESTRICTION_HISTORY_DATA_EXPORT_NG,
    RESTRICTION_HISTORY_DATA_EXPORT_OK,
  ],
  [RESTRICTION_HISTORY_MANAGE_INFORMATION]: [
    RESTRICTION_HISTORY_MANAGE_INFORMATION_NG,
    RESTRICTION_HISTORY_MANAGE_INFORMATION_OK,
  ],
  [RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT]: [
    RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_NG,
    RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_OK,
  ],
  [RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT]: [
    RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_NG,
    RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK,
  ],
  [RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT]: [
    RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_NG,
    RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_OK,
  ],
  [RESTRICTION_HISTORY_APPROVE]: [
    RESTRICTION_HISTORY_APPROVE_HIDE,
    RESTRICTION_HISTORY_APPROVE_VIEW,
    RESTRICTION_HISTORY_APPROVE_ADMIN,
  ],
  [RESTRICTION_HISTORY_APPLY_AREA]: [
    RESTRICTION_HISTORY_APPLY_AREA_HIDE,
    RESTRICTION_HISTORY_APPLY_AREA_VIEW,
    RESTRICTION_HISTORY_APPLY_AREA_EDIT,
  ],
  [RESTRICTION_HISTORY_FORM]: [
    RESTRICTION_HISTORY_FORM_HIDE,
    RESTRICTION_HISTORY_FORM_VIEW,
    RESTRICTION_HISTORY_FORM_EDIT,
  ],
  [RESTRICTION_HISTORY_TICKET]: [
    RESTRICTION_HISTORY_TICKET_HIDE,
    RESTRICTION_HISTORY_TICKET_VIEW,
    RESTRICTION_HISTORY_TICKET_EDIT,
  ],
  [RESTRICTION_HISTORY_PUBLIC]: [
    RESTRICTION_HISTORY_PUBLIC_HIDE,
    RESTRICTION_HISTORY_PUBLIC_VIEW,
    RESTRICTION_HISTORY_PUBLIC_EDIT,
  ],
  [RESTRICTION_BILLING_LIST]: [
    RESTRICTION_BILLING_LIST_NG,
    RESTRICTION_BILLING_LIST_OK,
  ],
};

//ユーザ権限
export const PERMISSION_ROLL_SYSADMIN = "10";
export const PERMISSION_ROLL_ADMIN = "20";
export const PERMISSION_ROLL_USER = "30";
export const PERMISSION_ROLL = [
  {label: "システム管理者", value: PERMISSION_ROLL_SYSADMIN},
  {label: "管理者", value: PERMISSION_ROLL_ADMIN},
  {label: "一般", value: PERMISSION_ROLL_USER},
];

// 帳票
export const REPORT_COLLECT_LIST = {
  label: "資料収集リスト",
  value: "0",
  region: [
    {label: "北海道開発局", value: "81"},
    {label: "東北地方整備局", value: "82"},
    {label: "関東地方整備局", value: "83"},
    {label: "北陸地方整備局", value: "84"},
    {label: "中部地方整備局", value: "85"},
    {label: "近畿地方整備局", value: "86"},
    {label: "中国地方整備局", value: "87"},
    {label: "四国地方整備局", value: "80"},
    {label: "九州地方整備局", value: "89"},
    {label: "沖縄地方整備局", value: "90"},
  ],
};
export const REPORT_MANAGE_LIST = {
  label: "提供用 供用予定管理リスト",
  value: "1",
  region: [
    {label: "全国", value: "0"},
    {label: "北海道開発局", value: "81"},
    {label: "東北地方整備局", value: "82"},
    {label: "関東地方整備局", value: "83"},
    {label: "北陸地方整備局", value: "84"},
    {label: "中部地方整備局", value: "85"},
    {label: "近畿地方整備局", value: "86"},
    {label: "中国地方整備局", value: "87"},
    {label: "四国地方整備局", value: "88"},
    {label: "九州地方整備局", value: "89"},
    {label: "沖縄地方整備局", value: "90"},
  ],
};
export const REPORT_OUTPUTS_STATUSES: { [key: number]: string } = {
  0: "処理待ち",
  1: "処理中",
  2: "完了",
  99: "エラー",
};

// attribute_settings
export const ATTRIBUTE_SETTING_IS_REQUIRED = "0010";
export const ATTRIBUTE_SETTING_LIMIT = "0020";
export const ATTRIBUTE_SETTING_DEFAULT_CODE = "0030";
export const ATTRIBUTE_SETTING_DEFAULT_VALUE = "0031";
export const ATTRIBUTE_SETTING_PARENT_ATTRIBUTE_ID = "0040";
export const ATTRIBUTE_SETTING_IS_AREA_RELATION = "0050";
export const ATTRIBUTE_SETTING_IS_BLOCK_RELATION = "0060";
export const ATTRIBUTE_SETTING_LIST_WIDTH = "0070";
export const ATTRIBUTE_SETTINGS_CODE_GEO_JSON = "0080";
export const ATTRIBUTE_SETTINGS_CODE_P_ID = "0081";
export const ATTRIBUTE_SETTINGS_CODE_NOT_EDIT = "0090";
export const ATTRIBUTE_SETTINGS_CODE_MARKDOWN = "0100";
export const ATTRIBUTE_SETTINGS_CODE_VERSION_NUMBER = "0110";
export const ATTRIBUTE_SETTINGS_CODE_IS_SECONDARY_MESH = "0120";
export const ATTRIBUTE_SETTINGS_CODE_IS_SECONDARY_MESH_NAME = "0121";
export const ATTRIBUTE_SETTINGS_CODE_IS_AUTO_INCREMENT = "0130";
export const ATTRIBUTE_SETTINGS_CODE_IS_PROGRESS_LIST = "0150";
export const ATTRIBUTE_SETTINGS_CODE_MANAGE_LIST_DISPLAY_ORDER = "0151";
export const ATTRIBUTE_SETTINGS_CODE_COLLECT_LIST_DISPLAY_ORDER = "0152";

// attribute_kinds_code
export const ATTRIBUTE_KINDS_CODE_TEXT_FIELD = "01";
export const ATTRIBUTE_KINDS_CODE_TEXT_AREA = "02";
export const ATTRIBUTE_KINDS_CODE_SELECT_BOX = "03";
export const ATTRIBUTE_KINDS_CODE_CHECK_BOX = "04";
export const ATTRIBUTE_KINDS_CODE_RADIO_BUTTON = "05";
export const ATTRIBUTE_KINDS_CODE_NUMBER_INT = "06";
export const ATTRIBUTE_KINDS_CODE_NUMBER_DECIMAL = "07";
export const ATTRIBUTE_KINDS_CODE_DATE = "08";
export const ATTRIBUTE_KINDS_CODE_TEXT = "09";
export const ATTRIBUTE_KINDS_CODE_MAP_DRAWING = "10";
export const ATTRIBUTE_KINDS_CODE_YEAR_MONTH = "11";

export const ATTRIBUTE_CODES = [
  ATTRIBUTE_KINDS_CODE_SELECT_BOX,
  ATTRIBUTE_KINDS_CODE_CHECK_BOX,
  ATTRIBUTE_KINDS_CODE_RADIO_BUTTON,
];
export const ATTRIBUTE_MULTIPLE_CODES = [ATTRIBUTE_KINDS_CODE_CHECK_BOX];

// 承認
export const APPROVAL_DECISION_STATUS_YET_CODE = "1";
export const APPROVAL_DECISION_STATUS_APPROVED_CODE = "2";
export const APPROVAL_DECISION_STATUS_YET_ID = 1;
export const APPROVAL_DECISION_STATUS_APPROVED_ID = 2;
export const APPROVAL_REQUEST_STATUS_YET_ID = 1;
export const APPROVAL_REQUEST_STATUS_APPLIED_ID = 2;

// メッセージ
export const MESSAGE_NO_E01 = "メールアドレスまたはパスワードが違います。";
export const MESSAGE_NO_E03 =
  "入力文字は半角英数字、-（ハイフン／半角）、_（アンダースコア／半角）のみ使用可能です。";
export const MESSAGE_NO_E05 = "選択したデータを一括削除しますか？";
export const MESSAGE_NO_E08 = "必須項目です。";
export const MESSAGE_NO_E09 =
  "登録をキャンセルしますか？入力したデータは登録されません。";
export const MESSAGE_NO_E11 = "データを削除しますか？";
export const MESSAGE_NO_E21 = "入力されたメールアドレスは利用できません。";
export const ERROR_MESSAGE_E23 = "インポートに失敗しました。";
export const SUCCESS_MESSAGE_E24 = "インポートが完了しました。";
export const MESSAGE_NO_E25 = "システムからログアウトしますか？";
export const MESSAGE_NO_E30 =
  "画面遷移しますか？入力したデータは登録されません。";
export const MESSAGE_NO_E32 = "ファイルを変更しますか？";
export const MESSAGE_NO_E35 = (num: number) => {
  return `${num}文字以内で入力してください。`;
};
export const MESSAGE_NO_E36 = "「備考」は200字以内で入力してください。";
export const MESSAGE_NO_E37 =
  "データの保存に失敗しました。再度実行してください。";
export const MESSAGE_NO_E39 =
  "データの取得に失敗しました。再度実行してください。";
export const MESSAGE_NO_E40 =
  "アップロードファイル数は100ファイル、またはデータ量は500MBまでになります。";
export const MESSAGE_NO_E41 = "コメントを送信してよろしいでしょうか？";
export const MESSAGE_NO_E66 = "コメントを入力してください。";
export const MESSAGE_NO_E67 = (num: number) => {
  return `${num}文字以上で入力してください。`;
};
export const MESSAGE_NO_E68 = (num: number) => {
  return `${num}文字で入力してください。`;
};
export const MESSAGE_NO_E72 = "詳細位置から登録してください。";

//
export enum Data {
  AttributeNew,
  AttributeEdit,
  AttributeCopy,
  AttributeDetail,
  ApplyAreaList,
  ApplyAreaListEdit,
  FileNew,
  FileEdit,
  FileList,
  Ticket,
  PublicDetail,
  PublicEdit,
  ApproveDetail,
  ApproveEdit,
  Form,
}

export enum Tab {
  Attribute,
  ApplyArea,
  Ticket,
  Public,
  File,
  Approve,
  Form,
}

export const tab = (isType: Data) => {
  switch (isType) {
    case Data.AttributeNew:
    case Data.AttributeEdit:
    case Data.AttributeCopy:
    case Data.AttributeDetail:
      return Tab.Attribute;
    case Data.ApplyAreaList:
    case Data.ApplyAreaListEdit:
      return Tab.ApplyArea;
    case Data.FileNew:
    case Data.FileEdit:
    case Data.FileList:
      return Tab.File;
    case Data.Ticket:
      return Tab.Ticket;
    case Data.PublicDetail:
    case Data.PublicEdit:
      return Tab.Public;
    case Data.ApproveDetail:
    case Data.ApproveEdit:
      return Tab.Approve;
    case Data.Form:
      return Tab.Form;
  }
};

export const isAttribute = (isType: Data) => {
  switch (isType) {
    case Data.AttributeNew:
    case Data.AttributeEdit:
    case Data.AttributeCopy:
    case Data.AttributeDetail:
      return true;
    case Data.FileNew:
    case Data.FileEdit:
    case Data.FileList:
      return false;
  }
};

export const isAttributeNew = (isType: Data) => {
  switch (isType) {
    case Data.AttributeNew:
    case Data.AttributeCopy:
      return true;
    default:
      return false;
  }
};

export const isDataEdit = (isType: Data) => {
  switch (isType) {
    case Data.AttributeNew:
    case Data.AttributeEdit:
    case Data.AttributeCopy:
    case Data.FileNew:
    case Data.FileEdit:
    case Data.PublicEdit:
    case Data.ApplyAreaListEdit:
      return true;
    default:
      return false;
  }
};

export enum DocumentManagement {
  FileNew,
  FileEdit,
  FileList,
}

export const importOptions = [
  {
    value: "1",
    label: "属性マスタ",
  },
  {
    value: "2",
    label: "属性選択肢マスタ",
  },
  {
    value: "3",
    label: "属性値",
  },
];

export const BASENAME = process.env.REACT_APP_BASENAME as string;

// 登録完了
export const PAYMENT_METHOD_REGISTER_STATUS_COMPLETE = "5";

// 課金区分（無償）
export const CUSTOMER_PAYMENT_TYPE_FREE = "1";
// 請求先（申込者）
export const BILLING_POST_TYPE_APPLICANT = "1";
// 事業体区分（法人）
export const BUSINESS_ENTITY_TYPE_CORPORATION = "1";

// 承認ステータス
export const CUSTOMER_STATUS_UNAPPROVED = "1"; // 未承認
export const CUSTOMER_STATUS_APPROVAL = "2"; // 承認

// 決済方法
export const PAYMENT_METHOD_CREDIT_CARD = "1"; // クレジットカード
export const PAYMENT_METHOD_MARUNAGE_ACCOUNT_TRANSFER = "15"; //まるなげ口座振替
export const PAYMENT_METHOD_MARUNAGE_BANK_CHECK = "16"; // まるなげバンクチェック

// 問い合わせ者区分
export const CUSTOMER_TYPE_ROAD_MANAGER = "1"; // 道路管理者
export const CUSTOMER_TYPE_NOT_ROAD_MANAGER = "2"; // 道路管理者以外

// 利用目的
export const USE_PURPOSE_ROAD_MANAGER_SYSTEM = "1"; // 21.xROAD道路管理者システム開発
export const USE_PURPOSE_OTHER_ROAD_MANAGER_SYSTEM = "2"; // 2.他の道路管理システム（個別GIS）との連携のため
export const USE_PURPOSE_OTHER_NOT_ROAD_MANAGER_SYSTEM = "3"; // 3.他の道路管理以外のシステムとの連携のため
export const USE_PURPOSE_NOT_ROAD_MANAGER_SYSTEM = "4"; // 4.道路管理者発注のシステム開発
export const USE_PURPOSE_IN_HOUSE_DEVELOPMENT = "5"; // 5.自社開発のシステム開発
export const USE_PURPOSE_ROAD_MANAGER_CONFIRM = "6"; // 6.道路管理業務の確認の為
export const USE_PURPOSE_OTHER = "7"; // 7.その他の目的の為

// 二次利用
export const IS_SECONDARY_USE = "1";

// 同意する
export const AGREE_CHECK = 1;

export const IS_AGREE = ["同意しない", "同意する"];
export const IS_BILLING_FLG = [
  {
    value: "0",
    label: "対象外",
  },
  {
    value: "1",
    label: "対象",
  },
];
export const CUSTOMER_STATUS = [
  {
    value: "1",
    label: "1.未承認",
  },
  {
    value: "2",
    label: "2.承認",
  },
];
export const BUSINESS_ENTITY_TYPE = [
  {
    value: "1",
    label: "1.法人",
  },
  {
    value: "2",
    label: "2.個人事業主",
  },
];
export const BILLING_POST_TYPE = [
  {
    value: "1",
    label: "1.申込者と同じ",
  },
  {
    value: "2",
    label: "2.申込者以外",
  },
];
export const PAYMENT_METHOD = [
  {
    value: "1",
    label: "1.クレジットカード決済",
  },
  {
    value: "15",
    label: "15.まるなげ口座振替",
  },
  {
    value: "16",
    label: "16.まるなげバンクチェック",
  },
];
export const PAYMENT_METHOD_REGISTER_STATUS = [
  {
    value: "0",
    label: "0.未処理",
  },
  {
    value: "1",
    label: "1.登録待ち",
  },
  {
    value: "2",
    label: "2.メール送信済み",
  },
  {
    value: "3",
    label: "3.申請中",
  },
  {
    value: "4",
    label: "4.登録情報_送信エラー",
  },
  {
    value: "5",
    label: "5.登録完了",
  },
  {
    value: "6",
    label: "6.登録失敗",
  },
  {
    value: "7",
    label: "7.まるなげ与信停止中",
  },
];
export const CREDIT_STATUS = [
  {
    value: "0",
    label: "0.却下",
  },
  {
    value: "1",
    label: "1.承認",
  },
  {
    value: "2",
    label: "2.申請中",
  },
];
export const USE_METHOD = [
  {
    value: "1",
    label: "1.都度APIを利用",
  },
  {
    value: "2",
    label: "2.DLデータを利用",
  },
];
export const USE_PURPOSE = [
  {
    value: "1",
    label: "1.xROAD道路管理者システム開発",
  },
  {
    value: "2",
    label: "2.他の道路管理システム（個別GIS）との連携のため",
  },
  {
    value: "3",
    label: "3.他の道路管理以外のシステムとの連携のため",
  },
  {
    value: "6",
    label: "6.道路管理業務の確認の為",
  },
  {
    value: "7",
    label: "7.その他の目的の為",
  },
];
export const USE_PURPOSE2 = [
  {
    value: "1",
    label: "1.xROAD道路管理者発注のシステム開発",
  },
  {
    value: "4",
    label: "4.道路管理者発注のシステム開発",
  },
  {
    value: "5",
    label: "5.自社開発のシステム開発",
  },
  {
    value: "6",
    label: "6.道路管理業務の確認の為",
  },
  {
    value: "7",
    label: "7.その他の目的の為",
  },
];
export const SYSTEM_STRUCTURE = [
  {
    value: "1",
    label: "1.据置ナビ",
  },
  {
    value: "2",
    label: "2.PND",
  },
  {
    value: "3",
    label: "3.スマホ組込み",
  },
  {
    value: "4",
    label: "4.システム",
  },
  {
    value: "5",
    label: "5.電子地図",
  },
  {
    value: "6",
    label: "6.センター地図型",
  },
];

export enum ApplyPdf {
  draft = "draft",
  estimate = "estimate",
  delivery = "delivery",
  invoice = "invoice",
}

export const getApplyPdfReportCode = (type: ApplyPdf) => {
  switch (type) {
    case ApplyPdf.draft:
      return REPORT_CODE_DRAFT;
    case ApplyPdf.estimate:
      return REPORT_CODE_ESTIMATE;
    case ApplyPdf.delivery:
      return REPORT_CODE_DELIVERY;
    case ApplyPdf.invoice:
      return REPORT_CODE_INVOICE;
  }
};

export enum ATTRIBUTE_COLUMN_NAME {
  viaMesh = "dic_37", // 経由メッシュ
  road = "dic_40", // 道路属性
  baseNumber = "dic_80", // 基本データ対応番号
  updatedMunicipalities = "updated_municipalities", //更新対象市町村
  itemNumber = "item_number", // 項番
  topographicMapName = "topographic_map_name", // 地形図名
  deliveryDate = "delivery_date", //納品希望日
  workDetail = "work_detail", //工事内容
  reference = "reference", //参考
  source01 = "source01", //公開ソース
  roadKind = "road_kind", // 道路種別
  routeNumber = "route_number", //路線番号
  routeName = "route_name", //路線名
  workDetailOther = "work_detail_other", //その他選択時の内容
  sectionStartpoint = "section_startpoint", //区間（起点）
  sectionEndpoint = "section_endpoint", //区間（終点）
  wantOfferDrawing = "want_offer_drawing", //提供していただきたい図面
  nowOfferDrawing = "now_offer_drawing", //今回提供していただく図面
  locationMap = "location_map", //位置図
  drawingOfferToMapCompany = "drawing_offer_to_map_company", //図面の地図会社への提供
  remarksForRoadAdministrator = "remarks_for_road_administrator", //備考
  length = "length", //延長
  commonStartYear = "common_start_ year", //供用開始予定（年度）
  commonStartMonth = "common_start_month", //供用開始予定（月）
  secondaryMeshNumber = "secondary_mesh_number", //2次メッシュ番号
  serviceInfoSource = "service_info_source" //情報源　※供用開始情報
}

export const COUNTABLE_ADOPTION_OR_NOT_OPTION = "2";

//検定機関グループのid
export const CERTIFICATION_GROUP_ID = 7;

export const BASIC_MAP_BLOCK_CODE = "99";

export enum DrmPfPage {
  Detail = "detail",
  Edit = "edit",
  Api = "api",
}

export const pageSizeOptions = [
  {
    value: "25",
    label: "25件",
  },
  {
    value: "50",
    label: "50件",
  },
  {
    value: "100",
    label: "100件",
  },
  {
    value: "200",
    label: "200件",
  },
];

export enum ServiceStartInformationList {
  progress,
  manage,
  collect,
}

export enum ServiceStartInfoCollectHeaderName {
  basicInfo = "道路の基本情報",
  serviceInfo = "道路の供用情報",
  drawing = "提供していただきたい図面・提供していただく図面",
  reference = "参考",
}

//郵便番号管理
export const CSV_POSTAL_CODE_IMPORT_TYPE = "4";
export const CSV_POSTAL_CODE_BUSINESS_IMPORT_TYPE = "5";

export const enum ServiceInfoSource {
  ROAD_ADMIN = "1",
  SERVICE_START = "2",
  INFRASTRUCTURE_PLAN = "3",
  PPI = "4",
  PREFECTURE_PROGRAM = "5",
  WEBSITE = "6",
  OFFICIAL_NOTICE = "7",
  FIELD_SURVEY = "8",
  OTHERS = "9",
}
export const ServiceInfoSourceLabels = {
  [ServiceInfoSource.ROAD_ADMIN]: '道路管理者提供',
  [ServiceInfoSource.SERVICE_START]: '供用開始情報（供用状況調査）',
  [ServiceInfoSource.INFRASTRUCTURE_PLAN]: '社会資本整備計画',
  [ServiceInfoSource.PPI]: 'PPI',
  [ServiceInfoSource.PREFECTURE_PROGRAM]: '都道府県公表プログラム等',
  [ServiceInfoSource.WEBSITE]: '道路管理者HP及び一般HP等',
  [ServiceInfoSource.OFFICIAL_NOTICE]: '官報告示情報（供用開始告示システム含む）',
  [ServiceInfoSource.FIELD_SURVEY]: '現地調査、衛星・航空写真',
  [ServiceInfoSource.OTHERS]: 'その他',
}