import create from 'zustand';

type DrmPfInformationListStore = {
  queryParams: any | undefined;
  setQueryParams: (any: any | undefined) => void;
};

// 検索条件の保持に使用
export const useDrmPfInformationListStore = create<DrmPfInformationListStore>((set) => ({
  queryParams: undefined,
  setQueryParams: (queryParams) =>
    set((state) => ({
      queryParams: queryParams,
    })),
}));
