import React from "react";

interface ToolTipTdProps {
  className?: string;
  children?: React.ReactNode;
}

export const ToolTipTd = ({children, className}: ToolTipTdProps) => {
  const text = () => {
    if (children === null || children === undefined) return '';
    if (Array.isArray(children)) return children.join('');
    return String(children);
  };
  return <td className={`${className}`} data-tooltip-id="customer-tooltip"
             data-tooltip-html={text()}>{children}</td>
}