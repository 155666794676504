import React from "react";
import {Attribute} from "@/components/ProgressList/types";
import {SharedTableHeadAttributes, SharedTableHeadTabNames} from "@/components/ProgressList/results/SharedTableHead";
import * as Config from "@/config";
import {SharedTableBodyAttributes, SharedTableBodyTabs} from "@/components/ProgressList/results/SharedTableBody";
import moment from "moment";
import * as Common from "@/utils/common";

interface SalesApplyTableHeadProps {
  desiredDeliveryDateAttr: Attribute[];
  decisionUsers: any[];
}

export const SalesApplyTableHead = (props: SalesApplyTableHeadProps) => {
  return (
    <>
      <th rowSpan={2}>
        <div className="border-box"/>
        申請状況
      </th>
      <th rowSpan={2}>
        <div className="border-box"/>
        承認状況
      </th>
      <SharedTableHeadAttributes/>
      <th rowSpan={2}>
        <div className="border-box"/>
        申込エリア
      </th>
      {/* 納品希望日 */}
      {props.desiredDeliveryDateAttr.map((attr: any) => {
        const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
        const width = listWidth ? `${listWidth.value}px` : '200px';
        return (<th rowSpan={2} style={{minWidth: width, maxWidth: width}}>
          <div className="border-box"/>
          {attr.name}</th>)
      })}
      {/* 承認者(日付) */}
      {props.decisionUsers.map((decisionUser: any) => {
        return (
          <th rowSpan={2} key={`th_decision_user_${decisionUser.id}`}>
            <div className="border-box"/>
            {decisionUser.position_name}
          </th>
        );
      })}
      <SharedTableHeadTabNames/>
    </>
  )

}

interface SalesApplyTableBodyProps {
  checked: boolean;
  data: any;
  desiredDeliveryDateAttr: Attribute[];
  decisionUsers: any[];
}

export const SalesApplyTableBody = (props: SalesApplyTableBodyProps) => {
  const applyAreas = props.data.data_applies.map((apply: any) => apply.data_apply_products.map((applyProduct: any) => applyProduct.sales_product.name)).flat(1);
  const requestStatus = props.data.data_approval_requests[0]?.approval_request_status_id === 2 ? '申請済み' : '未申請'
  const decisionStatus = Common.decisionStatus(props.data);
  const shapedApplyAreas = applyAreas.filter(function (item: any) {
    return item !== 'サンプル';
  });
  return (
    <>
      {/* 申請状況&承認状況 */}
      <td style={{minWidth: 60}} className={props.checked ? "selected" : ""}
          data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
        <div className="border-box"/>
        {requestStatus}
      </td>
      <td style={{minWidth: 60}} className={props.checked ? "selected" : ""}
          data-tooltip-id="progress-tooltip" data-tooltip-html={""}>
        <div className="border-box"/>
        {decisionStatus}
      </td>
      <SharedTableBodyAttributes data={props.data} checked={props.checked}/>
      {/* 申込エリア */}
      <td
        style={{minWidth: 100, maxWidth: 100}}
        data-tooltip-id="progress-tooltip" data-tooltip-html={shapedApplyAreas.join('<br />')}
        key={`td_apply_area_${props.data.id}`}
      >
        <div className="border-box"/>
        {shapedApplyAreas.join(', ')}
      </td>
      {/* 納品希望日 */}
      {props.desiredDeliveryDateAttr.map((attr: any) => {
        const attrValues = props.data.data_attribute_values.filter((attrValue: any) => attr.id === attrValue.attribute_id);
        const listWidth = attr.attribute_setting_values.find((setting_value: any) => setting_value.attribute_setting_code === Config.ATTRIBUTE_SETTING_LIST_WIDTH);
        const width = listWidth ? `${listWidth.value}px` : '200px';
        const content = attrValues.map((attrValue: any) => {
          if (Config.ATTRIBUTE_CODES.includes(attrValue.attribute_kind_code)) {
            return `${attrValue.attribute_code}: ${attrValue.attribute_value}`
          } else {
            return `${attrValue.attribute_value}`
          }
        });
        return (
          <td
            className="uk-text-left"
            style={{minWidth: width, maxWidth: width}}
            data-tooltip-id="progress-tooltip" data-tooltip-html={content.join('<br />')}
          >
            <div className="border-box"/>
            {content.join('\n')}
          </td>)
      })}
      {/* 承認者(日付) */}
      {props.decisionUsers.map((decisionUser) => {
        const style = {
          minWidth: 80,
          maxWidth: 80,
        }
        const key = `td_decision_user_${props.data.id}_${decisionUser.id}`;
        const approvalRequests = props.data.data_approval_requests;
        let content = '-';
        if (approvalRequests.length > 0) {
          const me = approvalRequests[0].data_approval_decision_users.find((u: any) =>
            u.is_decision_user === 1 && u.decision_users_id == decisionUser.id);
          if (me) {
            if (me.approval_decision_status.code === Config.APPROVAL_DECISION_STATUS_APPROVED_CODE) {
              content = `${moment(me.modified).format('YYYY/MM/DD')}\n${me.comments ?? ''}`;
            } else {
              content = me.comments ?? '';
            }
          }
        }
        return (
          <td
            style={style}
            key={key}
            data-tooltip-id="progress-tooltip" data-tooltip-html={content.replaceAll('\n', '<br/>')}>
            <div className="border-box"/>
            {content}
          </td>
        )
      })}
      <SharedTableBodyTabs data={props.data}/>
    </>
  )
}