import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../../components/SubHeader";
import ConfirmModal from "@/components/ConfirmModal";
import { useUserStore } from "@/stores/user";
import * as Common from "@/utils/common";
import * as Config from "@/config";

export default function Admin() {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const canUser =
    Common.canUserManagement(user) === Config.RESTRICTION_HISTORY_USER_OK.value;
  const canDataManagement =
    Common.canDataExportManagement(user) ===
      Config.RESTRICTION_HISTORY_DATA_EXPORT_OK.value ||
    Common.canDataImportManagement(user) ===
      Config.RESTRICTION_HISTORY_DATA_IMPORT_OK.value;
  const canInformationManagement =
    Common.canInformationManagement(user) ===
    Config.RESTRICTION_HISTORY_MANAGE_INFORMATION_OK.value;
  const canDocumentManagement =
    Common.canDocumentManagement(user) ===
    Config.RESTRICTION_HISTORY_DOCUMENT_MANAGEMENT_OK.value;
  const canBasicMapManagement =
    Common.canBasicMapManagement(user) ===
    Config.RESTRICTION_HISTORY_BASIC_MAP_MANAGEMENT_OK.value;
  const canMaterialCollectionListManagement =
    Common.canMaterialCollectionListManagement(user) ===
    Config.RESTRICTION_HISTORY_MATERIAL_COLLECTION_LIST_MANAGEMENT_OK.value;

  const breadCrumbs: { title: string; path?: string }[] = [
    { title: "管理画面" },
  ];

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  const onClickGroupManagement = () => {
    navigate("/admin/groups");
  };

  const onClickUserManagement = () => {
    navigate("/admin/users");
  };

  const onClickDataManagement = () => {
    navigate("/admin/data");
  };

  const onClickInformationManagement = () => {
    navigate("/admin/information");
  };

  const onClickDicDataManagement = () => {
    navigate("/admin/dic");
  };

  const onClickBasicMapManagement = () => {
    navigate("/admin/basic-map");
  };

  const onClickDocumentManagement = () => {
    navigate("/admin/document");
  };

  const onClickMaterialCollectionListManagement = () => {
    navigate("/admin/material-collection-list");
  };

  const onClickPortalManagement = () => {
    navigate("/admin/portal");
  };

  const onClickZipCodeManagement = () => {
    navigate("/admin/postal-code");
  };

  useEffect(() => {
    if (errorMessage) {
      setIsShowErrorModal(true);
    }
  }, [errorMessage]);

  return (
    <div>
      <div className="sub-header">
        <SubHeader breadCrumbs={breadCrumbs} title="管理画面" />
      </div>
      <div className="uk-flex uk-flex-center uk-flex-wrap mt-70 px-20">
        {user?.roll === "10" && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickGroupManagement}
          >
            グループ管理
          </button>
        )}
        {canUser && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickUserManagement}
          >
            ユーザ管理
          </button>
        )}
        {canDataManagement && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickDataManagement}
          >
            データ管理
          </button>
        )}
        {canInformationManagement && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickInformationManagement}
          >
            お知らせ管理
          </button>
        )}
        {canMaterialCollectionListManagement && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickMaterialCollectionListManagement}
          >
            資料収集管理
          </button>
        )}
        <button
          className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
          onClick={onClickDicDataManagement}
        >
          DICデータ管理
        </button>
        {canBasicMapManagement && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickBasicMapManagement}
          >
            基盤地図管理
          </button>
        )}
        {canDocumentManagement && (
          <button
            className="uk-button--l uk-button-refer uk-margin-left uk-margin-bottom"
            onClick={onClickDocumentManagement}
          >
            資料管理
          </button>
        )}
        <button
          className="uk-button--w uk-button-refer uk-margin-left uk-margin-bottom"
          onClick={onClickPortalManagement}
        >
          DRM-PFポータルサイト管理
        </button>

        <button
          className="uk-button--w uk-button-refer uk-margin-left uk-margin-bottom"
          onClick={onClickZipCodeManagement}
        >
          郵便番号管理
        </button>
      </div>
      {errorMessage && (
        <ConfirmModal
          text={errorMessage}
          confirmButtonText="OK"
          isShow={isShowErrorModal}
          onConfirm={() => {
            setIsShowErrorModal(false);
            setErrorMessage(null);
          }}
        />
      )}
    </div>
  );
}
