import React, {useState} from "react";
import {DrmPfApiUsagesOptions} from "@/containers/customer/usageHistory/useDrmPfApiUsages";
import {Fees} from "@/containers/customer/drmPf/types";
import {ToolTipTd} from "@/components/Customer/UsageHistory/ToolTipTd";
import {Tooltip} from "react-tooltip";

interface ApiGroupsProps {
  fees: Fees[];
}

export const ApiGroups = ({fees}: ApiGroupsProps) => {
  const {drm_pf_api_details_th} = DrmPfApiUsagesOptions();
  const [open, setOpen] = useState(false);

  return (
    <div className="uk-margin-small-top toggle-wrapper">
      <button type="button"
              className="uk-button uk-button-text uk-text-primary"
              onClick={() => setOpen((prev) => !prev)}
      >
        {open ? "▲" : "▼"} API利用料 内訳
      </button>
      <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${open ? "visible" : "hidden"}`}>
        <table className="API-table uk-width-auto">
          <thead>
          <tr>
            {drm_pf_api_details_th.map((item, index) => {
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
              );
            })}
          </tr>
          </thead>
          <tbody>
          {Object.values(fees).length !== 0 && Object.values(fees).map((item, index) => (
            <tr key={item.api_id}>
              <ToolTipTd>{item.api_id}</ToolTipTd>
              <ToolTipTd className="uk-text-right">{item.total} 件</ToolTipTd>
              <ToolTipTd className="uk-text-right">{item.temporary_free_total} 件</ToolTipTd>
              <ToolTipTd className="uk-text-right">{item.valid_total} 件</ToolTipTd>
              <ToolTipTd className="uk-text-right">¥ {item.unit_price}</ToolTipTd>
              <ToolTipTd className="uk-text-right">¥ {item.subtotal}</ToolTipTd>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}