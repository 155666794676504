import React, {Fragment, memo, useEffect, useMemo, useRef, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import * as Config from '@/config'
import * as Common from '@/utils/common'
import {axios} from '@/lib/axios';
import Map from '@/components/Map';
import {generateRandom8DigitNumber, getCurrentTimestamp} from "@/utils/common";
import * as PfAPI from '@/api/pf';
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";
import {useMapDrawing} from "@/stores/mapDrawing";

// TODO: 共通化, props整理
export const InputText = ({attribute, props}: { attribute: any, props?: any }) => {
  const {register} = useFormContext();
  const {formState: {errors},} = useFormContext<{ attributes: Array<any> }>(); // TODO: 書き方
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      <input
        key={name}
        type={props?.type}
        className={`${props?.className} ${error ? "error-form" : ""}`}
        {...register(name)}
        disabled={props?.disabled}
      />
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}

export const InputTextArea = ({attribute, props}: { attribute: any, props?: any }) => {
  const {register} = useFormContext();
  const {formState: {errors},} = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;
  const isMapButton = Common.isMapButtonActive(attribute);

  return (
    <>
      <textarea
        className={`${props?.className} ${error ? "error-form" : ""}`}
        {...register(name)}
        disabled={isMapButton}
      />
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}

export const hasRelation = (attribute: any) => {
  for (const setting of attribute.attribute_setting_values) {
    if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_PARENT_ATTRIBUTE_ID)
      return [`attributes.${setting.value}`, 'parent_attribute_options_id'];
    if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_IS_AREA_RELATION)
      return ['area_id', 'parent_area_id'];
    if (setting.attribute_setting_code === Config.ATTRIBUTE_SETTING_IS_BLOCK_RELATION)
      return ['block_id', 'parent_block_id'];
  }
  return [null, null];
}

export const HasRelation = ({attribute, parentName, filterName, props}: {
  attribute: any,
  parentName: string,
  filterName: string,
  props?: any
}) => {
  const {control, register, setValue, resetField, formState: {isDirty}} = useFormContext();
  const name = `attributes.${attribute.id}`;

  const selectedParentAttribute = useWatch({
    control,
    name: parentName,
  });

  const selectedAttribute = useWatch({
    control,
    name: name,
  });

  const filteredOptions = (selectedAttr: any) => {
    return attribute.attribute_options.filter((option: any) => {
      return option.attribute_option_parent_values.some((parentValue: any) => {
        // TODO: area_idとblock_idはidなので統一性がない(postと入力値取得時にcodeとvalueに変換？)
        return parentValue[filterName] === parseInt(selectedAttr.split(Config.SPLIT_CODE)[0])
      });
    });
  }

  const filteredAttributes = useMemo(() => {
    // console.log(`useMemo\nname::${name}\nparentName::${parentName}\nselectedParentAttribute::${selectedParentAttribute}`)
    if (selectedParentAttribute) {
      if (Array.isArray(selectedParentAttribute)) {
        const resultOptions: any[] = [];
        selectedParentAttribute.forEach(attr => resultOptions.push(...filteredOptions(attr)));
        return resultOptions;
      } else {
        return filteredOptions(selectedParentAttribute);
      }
    }
    return [];
  }, [selectedParentAttribute]);

  useEffect(() => {
    if (isDirty) {
      // console.log(`useEffect\nname::${name}\nparentName::${parentName}\nselectedParentAttribute::${selectedParentAttribute}`)
      setValue(name, '');
    } else {
      setValue(name, selectedAttribute);
    }
  }, [filteredAttributes]);

  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
      return <SelectBox attribute={attribute} options={filteredAttributes} props={props}/>
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
      return <CheckBox attribute={attribute} options={filteredAttributes} props={props}/>
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      return <RadioButton attribute={attribute} options={filteredAttributes} props={props}/>
    default:
      return null;
  }
}

export const NoneRelation = ({attribute, props}: { attribute: any, props?: any }) => {
  const name = `attributes.${attribute.id}`;
  const isVersionNumber = Common.isVersionNumber(attribute);
  const options = isVersionNumber ? Common.validVersionNumber(attribute.attribute_options) : attribute.attribute_options;

  switch (attribute.attribute_kind_code) {
    case Config.ATTRIBUTE_KINDS_CODE_SELECT_BOX:
      return <SelectBox attribute={attribute} options={options} props={props}/>
    case Config.ATTRIBUTE_KINDS_CODE_CHECK_BOX:
      return <CheckBox attribute={attribute} options={options} props={props}/>
    case Config.ATTRIBUTE_KINDS_CODE_RADIO_BUTTON:
      return <RadioButton attribute={attribute} options={options} props={props}/>
    default:
      return null;
  }
}

export const SelectBox = ({attribute, options, props}: { attribute: any, options: any, props?: any }) => {
  const {register} = useFormContext();
  const {formState: {errors},} = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      <select className={`${props?.className} ${error ? "error-form" : ""}`}
              key={name}  {...register(name)}>
        <option value="">選択してください</option>
        {options.map((o: any) => (
          <option key={`option_${attribute.id}_${o.id}`}
                  value={Common.inputAttributeOptionValue(o)}>{o.code + ': ' + o.option}</option>
        ))}
      </select>
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}

export const CheckBox = ({attribute, options, props}: { attribute: any, options: any, props?: any }) => {
  const {register} = useFormContext();
  const {formState: {errors},} = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      {options.map((o: any) => (
        <label className="inline-label">
          <input type="checkbox" {...register(name)} value={Common.inputAttributeOptionValue(o)}/>
          {o.code + ': ' + o.option}
        </label>
      ))}
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}

export const RadioButton = ({attribute, options, props}: { attribute: any, options: any, props?: any }) => {
  const {register} = useFormContext();
  const {formState: {errors},} = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      {options.map((o: any) => (
        <label className="inline-label">
          <input type="radio" {...register(name)} value={Common.inputAttributeOptionValue(o)}/>
          {o.code + ': ' + o.option}
        </label>
      ))}
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}

interface MapDrawingErrors {
  prefecture?: string;
  city?: string;
  confirm?: string;
}

export const InputMapDrawing = ({attribute, props}: { attribute: any, props?: any }) => {
  const {getValues, formState: {errors},} = useFormContext<{
    attributes: Array<any>
  }>();
  const error = errors.attributes?.[attribute.id];
  const [mapDrawingErrors, setMapDrawingErrors] = useState<MapDrawingErrors | null>(null);

  const setLoading = useLoadingStore((state) => state.setLoading);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const {
    currentLocation,
    setCurrentLocation,
    coordinate,
    setCoordinate,
    setP_id,
    reset: resetMapDrawingStore
  } = useMapDrawing();

  const [firstRender, setFirstRender] = useState(false);

  const [prefectures, setPrefectures] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const attrValue = getValues(`attributes.${attribute.id}`);

  const filteredCities = useMemo(() => {
    if (prefectures && cities && prefectures.length > 0 && cities.length > 0) {
      return cities.filter((item) => String(item.prefecture_id) === String(currentLocation.prefecture));
    }
    return cities;
  }, [prefectures, cities, currentLocation.prefecture]);

  const generateFeatureKey = () => {
    let key = '';
    if (currentLocation.featureKey.length > 0) {
      key = currentLocation.featureKey
    } else {
      key = generateRandom8DigitNumber();
      key = getCurrentTimestamp() + generateRandom8DigitNumber();
      setCurrentLocation({...currentLocation, featureKey: key})
    }
    return key;
  }
  const openExternalSite = () => {

    let coordinate: any;
    if (currentLocation.city.length > 0) {
      setMapDrawingErrors(null);
      const cityData = cities.find(item => String(item.id) === String(currentLocation.city));
      if (cityData) {
        coordinate = Common.parseCoordinate(cityData.coordinate);
      }
    } else {
      setMapDrawingErrors({city: Config.MESSAGE_NO_E08});
    }

    !(currentLocation.prefecture.length > 0) && setMapDrawingErrors({
      city: Config.MESSAGE_NO_E08,
      prefecture: Config.MESSAGE_NO_E08
    });

    if (coordinate) {
      const key = generateFeatureKey();
      window.open(`${Config.PF_DRM_BASE_URL}/FreeViewer/index.php?feature=&featurekey=${key}&systemID=drm-dx#13/${coordinate.x}/${coordinate.y}/`, "_blank");
    }
  }

  const mapConfirm = async () => {
    setLoading(true);
    const authRes = await PfAPI.authentication();
    PfAPI.getCoordinate(authRes, currentLocation.featureKey).then(result => {
      setCoordinate(result);
    }).catch(() => {
      setMessage(Config.MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    })
  }

  useEffect(() => {
    setFirstRender(false);
    resetMapDrawingStore()
    axios.get(`/api/v1/attributes/getPrefsAndCitiesData`)
      .then((result) => {
        setPrefectures(result.data.prefectures);
        setCities(result.data.cities);
      });
  }, []);

  //useContextFormの初期値値更新より先にコンポーネントが描画されてしまうため手動初期値更新
  useEffect(() => {
    if (!coordinate && currentLocation.featureKey.length > 0) {
      mapConfirm();
    }
  }, [firstRender])
  useEffect(() => {
    if (attrValue && !firstRender) {
      const parsedLocation = JSON.parse(attrValue);
      setCurrentLocation(parsedLocation)
      setFirstRender(true);
      // console.log("firstRender")
    }
  }, [attrValue]);

  return (
    <>
      <div className="map-drawing-form">
        <p className="">都道府県</p>
        <select
          className="uk-margin-small-left width-165"
          value={currentLocation.prefecture}
          key={"prefecture"}
          onChange={e => setCurrentLocation({...currentLocation, city: "", prefecture: e.target.value})}
        >
          <option value="">選択してください</option>
          {prefectures.map((o: any) => (
            <option key={`option_${o.id}`} value={o.id}>{o.id + ': ' + o.name}</option>
          ))}
        </select>
        {mapDrawingErrors?.prefecture && <div className="error">{`${mapDrawingErrors.prefecture}`}</div>}
        <p className="">市区町村</p>
        <select
          className="uk-margin-small-left width-165"
          value={currentLocation.city}
          key={"city"}
          onChange={e => setCurrentLocation({...currentLocation, city: e.target.value})}
        >
          <option value="">選択してください</option>
          {filteredCities.map((o: any) => (
            <option key={`option_${o.id}`} value={o.id}>{o.id + ': ' + o.name}</option>
          ))}
        </select>
        {mapDrawingErrors?.city && <div className="error">{`${mapDrawingErrors.city}`}</div>}
        {error && <div className="error">{`${error}`}</div>}
      </div>
      <div className="map-drawing-form">
        <p>
          別タブでDRM-PFが開きます。地図に図形を登録してください。
        </p>
        <button
          className="uk-button--l uk-button-cancel uk-margin-small-left"
          type="button"
          onClick={openExternalSite}
        >
          詳細位置
        </button>
        {mapDrawingErrors?.confirm && <div className="error">{`${mapDrawingErrors.confirm}`}</div>}
      </div>
      <div className="uk-position-relative height-300">{coordinate && <Map geojsonData={coordinate}/>}</div>
    </>
  )
}

export const MapDrawing = ({value}: { value: string }) => {
  const setLoading = useLoadingStore((state) => state.setLoading);
  const {coordinate, setCoordinate} = useMapDrawing();
  const location = JSON.parse(value);

  const mapConfirm = async () => {
    if (!location.featureKey) {
      return;
    }
    setLoading(true)
    const authRes = await PfAPI.authentication();
    PfAPI.getCoordinate(authRes, location.featureKey).then(result => {
      setCoordinate(result);

    }).finally(() => {
      setLoading(false)
    });
  }

  useEffect(() => {
    mapConfirm();
  }, [])

  return <>{coordinate && <Map geojsonData={coordinate}/>}</>
}

export const BasicMapStatus = ({name, options, props}: { name: string, options: any[], props?: any }) => {
  const {register, getValues, formState: {errors},} = useFormContext();
  const error = errors[name]?.message;

  return (
    <>
      <select
        className={`uk-width-1-1 ${props?.className} ${error ? "error-form" : ""}`}
        disabled={props?.disabled}
        defaultValue={props?.defaultValue ?? ""}
        key={name}
        {...register(name)}
      >
        <option value="">選択してください</option>
        {options?.map((o: any) => (
          <option key={`option_${name}_${o.id}`}
                  value={o.id}>{o.name}</option>
        ))}
      </select>
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}

export const Editions = ({attribute, options, props}: { attribute: any, options: any, props?: any }) => {
  const {register} = useFormContext();
  const {formState: {errors},} = useFormContext<{ attributes: Array<any> }>();
  const name = `attributes.${attribute.id}`;
  const error = errors.attributes?.[attribute.id]?.message;

  return (
    <>
      <select className={`${props?.className} ${error ? "error-form" : ""}`}
              key={name}  {...register(name)}>
        <option value="">選択してください</option>
        {options.map((o: any) => (
          <option key={`option_${attribute.id}_${o.id}`}
                  value={Common.inputAttributeOptionValue(o)}>{o.code + ': ' + o.option}</option>
        ))}
      </select>
      {error && <div className="error">{`${error}`}</div>}
    </>
  )
}