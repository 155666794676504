import * as Config from "@/config";

export function useSearchBasicMap() {
//検索項目追加
  const insertAfterItem = <T>(
    array: T[],
    predicate: (item: T) => boolean,
    newItem: T
  ): T[] => {
    const index = array.findIndex(predicate);

    if (index === -1) {
      return [...array, newItem];
    }

    return [
      ...array.slice(0, index + 1),
      newItem,
      ...array.slice(index + 1)
    ];
  }
//検索項目追加
  const basicMapSearchItem = (attributes: any[], options: any) => {
    let item;
    item = insertAfterItem(
      attributes,
      attr => attr.column_name === Config.ATTRIBUTE_COLUMN_NAME.updatedMunicipalities,
      {
        id: 997,
        name: "更新対象市区町村ステータス",
        isStatus: "basic_map_statuses.updated_city_status",
        options: options.updatedCityStatus,
      })
    item.push({
      id: 998,
      name: "作成会社ステータス",
      isStatus: "basic_map_statuses.submission_status",
      options: options.submissionStatus
    })
    item.push({
      id: 999,
      name: "検定機関ステータス",
      isStatus: "basic_map_statuses.certification_status",
      options: options.certificationStatus
    })

    return item;
  }
  //検索結果ステータス
  type Status = {
    certification_status?: number;
    submission_status?: number;
    data_id: number;
  }
  type BasicMapStatus = {
    certification_status: Status;
    submission_status: Status;
  }

  type Option = {
    id?: number | string;
    code?: number;
    name: string;
  }

  const getSearchResultBasicMapStatus = (basicMapStatus: BasicMapStatus[], dataId: number, certificationStatus: Option[], submissionStatus: Option[]) => {
    const filteredResult = basicMapStatus.find((basicMap) => {
      return basicMap.certification_status.data_id === dataId;
    });
    const certification = certificationStatus.find((item) => {
      return item.code === filteredResult?.certification_status.certification_status
    })?.name
    const submission = submissionStatus.find((item) => {
      return item.code === filteredResult?.submission_status.submission_status
    })?.name

    return {certification, submission}
  }

  return {basicMapSearchItem, getSearchResultBasicMapStatus}
}