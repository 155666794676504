import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useLoadingStore} from "@/stores/loading";
import {useMessageModalStore} from "@/stores/message";
import ConfirmModal from "@/components/ConfirmModal";
import {
  IS_BILLING_FLG,
  MESSAGE_NO_E39,
} from "@/config";
import {useNavigate, useParams} from 'react-router-dom';
import {DrmPfApiUsagesOptions, useDrmPfApiUsages} from "@/containers/customer/drmPf/usages/useDrmPfApiUsages";
import {Bills, Fees, Logs, Months, UsageHistorySearchDate} from "@/containers/customer/drmPf/types";
import {axios} from "@/lib/axios";
import qs from 'qs';
import {useDrmPfApiUsages as useDrmPfApiUsagesExcel} from "@/containers/customer/usageHistory/useDrmPfApiUsages";
import {useDrmPfDetail} from "@/containers/customer/drmPf/useDrmPf";

export default function APIUsagesDetail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const UsagesOptions = DrmPfApiUsagesOptions();
  const {CurrentDate} = useDrmPfApiUsages();
  useDrmPfDetail();
  const customerId = useParams().customerId;
  const [setLoading,isLoading] = useLoadingStore(state => [state.setLoading,state.isLoading]);
  const setMessage = useMessageModalStore(state => state.setMessage);
  const {downloadExcel} = useDrmPfApiUsagesExcel();

  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState<Logs[]>([]);
  const [fees, setFees] = useState<Fees[]>([]);
  const [bills, setBills] = useState<Bills[]>([]);
  const [billingFlg, setBillingFlg] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  // 検索項目
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  // 検索結果
  const [searchQueryParam, setSearchQueryParam] = useState<any>(undefined);
   
  useEffect(() => {
    // URLパラメータから検索条件を取得し、状態に反映する
    const params = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });
//console.log(params);
    if (typeof params.from_date === 'string') {
      setFromDate(params.from_date);
    }
    if (typeof params.to_date === 'string') {
      setToDate(params.to_date);
    }
  }, [searchParams]);

  const navigate = useNavigate();

  const onClickToggle = () => setOpen((prev) => !prev);

  const onSearch = () => {
    console.log('customerId=' + customerId);
    console.log('fromDate=' + fromDate);
    console.log('toDate=' + toDate);

    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
    }
    //console.log("-----検索ボタン押下-----");
    //console.log(params);
    doSearch(params);
  }

  // 検索処理
  function doSearch(params: any) {
    //console.log("------ doSearch -----");
    console.log("----- params ------");
    console.log(params);

    setLoading(true);
    axios.get('/api/v1/customers/getDrmPfApiUsagesData', {
      //params
      params: params
    }).then(result => {
      console.log(result.data)
      console.log(result.data.pgiongoarams)
      setLogs(result.data.displayLogData);
      setFromDate(result.data.from_date);
      setToDate(result.data.to_date);
      setBills(result.data.billData);
      setFees(result.data.apiGroup);
      //setSelectMonths(result.data.selectMonths);
      if (result.data.logData[0]['is_billing'] != null) {
        const billig_flg = IS_BILLING_FLG.find((output, index) => {
          return output.value == result.data.logData[0]['is_billing'];
        });
        setBillingFlg(billig_flg?.label || '');
      }
    }).catch(error => {
      setErrorMessage(MESSAGE_NO_E39);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    //console.log('customerId=' + customerId);
    //console.log('fromDate=' + fromDate);
    //console.log('toDate=' + toDate);

    const params = {
      id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
    }
    doSearch(params);
  }, []);

  const onExcel = () => {
    const params = {
      customer_id: Number(customerId),
      from_date: fromDate,
      to_date: toDate,
    }
    setLoading(true);
    const postParams: { [key: string]: any; } = {
      'params': params,
    };

    axios.post('/api/v1/customers/exportUsageHistory', postParams, {
      responseType: 'blob',
    })
      .then(response => {
        downloadExcel(response);
      })
      .catch(error => {
        setMessage(MESSAGE_NO_E39);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="info-tbl_wrap uk-margin-small-top">
      <table className="API-table uk-width-1-1">
        <tbody>
        <tr>
          <th className="label uk-text-center uk-background-muted">照会年月日</th>
          <td className="content uk-flex uk-flex-between">
            <div>
              <div className="uk-inline">
                <input
                  name="to_date"
                  type="date"
                  className="uk-width-1-1"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="uk-inline">
                ～
              </div>
              <div className="uk-inline">
                <input
                  name="from_date"
                  type="date"
                  className="uk-width-1-1"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="uk-inline">
                <span className="uk-margin-small-left">※暫定</span>
              </div>
              <div className="uk-inline uk-margin-large-left">
                <button type="button" className="uk-button--m uk-button-refer" onClick={() => onSearch()}>検索</button>
              </div>
            </div>
            <div className="uk-flex-right">
              <button className="uk-button--m uk-button-refer uk-margin-left" onClick={() => onExcel()}>Excel出力</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div className="uk-width-1-1 uk-overflow-auto">
        <table className="API-table uk-margin-small-top">
          <thead>
          <tr>
            {UsagesOptions.drm_pf_api_summary_th.map((item, index) => {
              const replaced_label = item.label.split(/(\n)/).map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.match(/\n/) ? <br/> : item}
                  </React.Fragment>
                );
              });
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className={`label uk-text-center ${item.bold && "uk-text-bold"}`}
                    style={style}
                    rowSpan={item.rowspan}
                    colSpan={item.colspan}
                    key={index}
                >
                  {replaced_label}
                </th>
              );
            })}
          </tr>
          <tr>
            {UsagesOptions.drm_pf_api_summary_th_2nd.map((item, index) => {
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
              );
            })}
          </tr>
          </thead>
          <tbody>
          {Object.values(bills).length !== 0 && Object.values(bills).map((item, index) => (
            <tr key={index}>
              <td className="uk-text-center">{item.paymentType}</td>
              <td className="uk-text-right">¥ {item.basicFee}</td>
              <td className="uk-text-right">{item.validTotalAll} 件</td>
              <td className="uk-text-right">¥ {item.undiscountPriceTotal}</td>
              <td className="uk-text-right">¥ {item.discountAmount}</td>
              <td className="uk-text-right">{item.realDiscountRate} %</td>
              <td className="uk-text-right">¥ {item.discountPriceTotal}</td>
              <td className="uk-text-right">{item.rate} %</td>
              <td className="uk-text-right">{item.termDiscounts} %</td>
              <td className="uk-text-right">¥ {item.allDiscountPriceTotal}</td>
              <td className="uk-text-right uk-text-bold">¥ {item.billingAmount}</td>
              <td className="uk-text-right uk-text-bold">{item.tax} %</td>
              <td className="uk-text-right uk-text-bold">¥ {item.includeTax}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>

      <div className="uk-margin-small-top toggle-wrapper">
        <button type="button"
                className="uk-button uk-button-text uk-text-primary"
                onClick={onClickToggle}
        >
          {open ? "▲" : "▼"} API利用料 内訳
        </button>
        <div className={`toggle-contents uk-margin-small-top uk-overflow-auto ${open ? "visible" : "hidden"}`}>
          <table className="API-table uk-width-auto">
            <thead>
            <tr>
              {UsagesOptions.drm_pf_api_details_th.map((item, index) => {
                const style: React.CSSProperties = {
                  width: item.width
                };
                return (
                  <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {Object.values(fees).length !== 0 && Object.values(fees).map((item, index) => (

              <tr key={item.api_id}>
                <td>{item.api_id}</td>
                <td className="uk-text-right">{item.total} 件</td>
                <td className="uk-text-right">{item.temporary_free_total} 件</td>
                <td className="uk-text-right">{item.valid_total} 件</td>
                <td className="uk-text-right">¥ {item.unit_price}</td>
                <td className="uk-text-right">¥ {item.subtotal}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <p className="uk-text-right uk-margin-remove-bottom">
        ※こちらの小計はボリュームディスカウント前の金額となります。
      </p>
      <div className="uk-width-1-1 uk-overflow-auto">
        <table className="API-table">
          <thead>
          <tr>
            {UsagesOptions.drm_pf_api_usages_th.map((item, index) => {
              const style: React.CSSProperties = {
                width: item.width
              };
              return (
                <th className="label uk-text-center" style={style} key={index}>{item.label}</th>
              );
            })}
          </tr>
          </thead>
          <tbody>
          {logs.length !== 0 && logs.map((item, index) => (
            <tr key={index}>
              <td>{item.start_date}</td>
              <td>{item.end_date}</td>
              <td>{item.ip_address}</td>
              <td>{item.api_id}</td>
              <td className="uk-text-center">{item.processed_result}</td>
              <td className="uk-text-center">{item.processed_kind}</td>
              <td className="uk-text-center">{item.edition_information}版</td>
              <td className="uk-text-right">{item.data_number} 件</td>
              <td className="uk-text-right">¥ {item.unit_price}</td>
              <td className="uk-text-center">{item.is_billing_str}</td>
              {item.is_temporary_free === 1 ? <td className="uk-text-right"> -{item.data_number} 件</td> :
                <td className="uk-text-right"></td>}
              <td className="uk-text-right">¥ {item.subtotal}</td>
            </tr>
          ))}

          </tbody>
        </table>
      </div>
      {errorMessage &&
          <ConfirmModal
              text={errorMessage}
              confirmButtonText="OK"
              isShow={isShowErrorModal}
              onConfirm={() => {
                setIsShowErrorModal(false);
                setErrorMessage(null);
                navigate("/drm_pf/info");
              }}
          />
      }
    </div>
  );
}
