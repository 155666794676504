import React from 'react';
import {UsageHistorySearchState} from "@/containers/customer/drmPf/types";

interface SearchSectionProps {
  searchState: UsageHistorySearchState;
  setSearchState: React.Dispatch<React.SetStateAction<UsageHistorySearchState>>
  onSearch: () => void;
  customerTypes: Option[];
  customerGroupTypes: Option[];
  customerPaymentTypes: Option[];
  onClear: () => void;
}

interface Option {
  value: string,
  label: string,
}

export const SearchSection = (props: SearchSectionProps) => {
  const {
    searchState,
    setSearchState,
    onSearch,
    customerTypes,
    customerGroupTypes,
    customerPaymentTypes,
    onClear
  } = props;

  const updateSearchState = (updates: Partial<typeof searchState>) => {
    setSearchState(prevState => ({
      ...prevState,
      ...updates
    }));
  }
  const updateCustomerType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = parseInt(e.target.value, 10);
    const updatedIds = searchState.customer_type_id ? [...searchState.customer_type_id] : [];
    if (e.target.checked) {
      updatedIds.push(selectedValue);
    } else {
      const index = updatedIds.indexOf(selectedValue);
      if (index !== -1) {
        updatedIds.splice(index, 1);
      }
    }
    updateSearchState({customer_type_id: updatedIds});
  }

  const updateCustomerPaymentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = parseInt(e.target.value, 10);
    let updatedIds = searchState.customer_payment_type_id ? [...searchState.customer_payment_type_id, selectedValue] : [selectedValue];
    if (searchState.customer_payment_type_id?.includes(selectedValue)) {
      updatedIds = updatedIds.filter(day => day != selectedValue);
    }
    updateSearchState({customer_payment_type_id: updatedIds});
  }

  return (
    <form>
      <table className="condition-tbl">
        <tbody>
        <tr className="header">
          <th>顧客番号</th>
          <th>法人・団体名</th>
          <th>姓</th>
          <th>名</th>
          <th>問合せ者区分</th>
          <th>団体区分</th>
          <th>課金区分</th>
          <th>照会年月</th>
        </tr>
        <tr>
          <td>
            <input
              className="w-100"
              type="text"
              value={searchState.customer_number}
              onChange={(e) => updateSearchState({customer_number: e.target.value})}
            />
          </td>
          <td>
            <input
              type="text"
              value={searchState.group_name}
              onChange={(e) => updateSearchState({group_name: e.target.value})}
            />
          </td>
          <td>
            <input
              className="w-100"
              type="text"
              value={searchState.last_name}
              onChange={(e) => updateSearchState({last_name: e.target.value})}
            />
          </td>
          <td>
            <input
              className="w-100"
              type="text"
              value={searchState.first_name}
              onChange={(e) => updateSearchState({first_name: e.target.value})}
            />
          </td>
          <td>
            <div className="uk-flex uk-flex-wrap">
              {customerTypes?.map(customerType => (
                <label key={customerType.value} className="uk-margin-small-right">
                  <input
                    type="checkbox"
                    name="customer_type_id[]"
                    value={customerType.value}
                    onChange={updateCustomerType}
                    checked={searchState?.customer_type_id?.includes(Number(customerType.value))}
                  />
                  {customerType.label}
                </label>
              ))}
            </div>
          </td>
          <td>
            <div>
              <select
                className={`${searchState.customer_group_type_id ? '' : 'placeholder'}`}
                defaultValue={""}
                value={searchState.customer_group_type_id}
                name="customer_group_type_id"
                onChange={(e) => {
                  updateSearchState({customer_group_type_id: e.target.value});
                }}
              >
                <option value="">団体区分選択</option>
                {customerGroupTypes?.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </td>
          <td>
            <div className="uk-flex uk-flex-wrap">
              {customerPaymentTypes?.map(customerPaymentType => (
                <label key={customerPaymentType.value} className="uk-margin-small-right">
                  <input
                    type="checkbox"
                    name="customer_payment_type_id[]"
                    value={customerPaymentType.value}
                    onChange={updateCustomerPaymentType}
                    checked={searchState.customer_payment_type_id?.includes(customerPaymentType.value)}
                  />
                  {customerPaymentType.label}
                </label>
              ))}
            </div>
          </td>
          <td>
            <div className="uk-flex uk-flex-wrap">
              <div className="uk-inline">
                <input
                  name="to_date"
                  type="date"
                  className="uk-width-1-1"
                  value={searchState.from_date}
                  onChange={(e) => updateSearchState({from_date: e.target.value})}
                />
              </div>
              <div className="uk-inline">
                ～
              </div>
              <div className="uk-inline">
                <input
                  name="from_date"
                  type="date"
                  className="uk-width-1-1"
                  value={searchState.to_date}
                  onChange={(e) => updateSearchState({to_date: e.target.value})}
                />
              </div>
              <span className="uk-margin-small-left">※暫定</span>
            </div>
          </td>
        </tr>

        <tr>
          <td colSpan={8}>
            <div className="uk-flex uk-flex-center">
              <button type="button" className="uk-button--m uk-button-refer" onClick={() => onSearch()}>検索
              </button>
              <button type="button" className="uk-button--m uk-button-cancel uk-margin-left"
                      onClick={() => onClear()}>クリア
              </button>
            </div>
          </td>
        </tr>

        </tbody>
      </table>
    </form>
  )
}